import { createGlobalStyle } from "styled-components";

import MontserratVariable from "fonts/Montserrat-VariableFont_wght.ttf";
import AlexandriaVariable from "fonts/Alexandria-VariableFont_wght.ttf";
import MartianMonoVariable from "fonts/MartianMono-VariableFont_wdth,wght.ttf";

const Fonts = createGlobalStyle`
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratVariable}) format("woff2-variations");
    font-weight: 100 900;
  }

  @font-face {
    font-family: "Alexandria";
    src: url(${AlexandriaVariable}) format("woff2-variations");
    font-weight: 100 900;
  }

  @font-face {
    font-family: "Martian Mono";
    src: url(${MartianMonoVariable}) format("woff2-variations");
    font-weight: 100 900;
  }

  body {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: ${props => props.textColor};
  }
`;

export default Fonts;
