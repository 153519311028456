import ContactUsModal from "components/ContactUsModal";
import { Gap } from "components/Layout";
import { ButtonLink, MonoText, Tag, Title } from "components/v3/shared";
import styled from "styled-components";

const SIDE_MARGIN = 150;

const Container = styled.div`
  display: grid;
  padding-left: ${SIDE_MARGIN}px;
  align-items: center;
  padding: 120px 150px;
  padding-top: 200px;
  background-color: #1f1f1f;
  color: #ffffff;
`;

const Logos = styled.div`
  padding: 33px 0;

  display: flex;
  gap: 64px;
  align-items: center;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    padding: 20px;
    margin-right: 0;
    overflow-x: auto;
    gap: 20px;
    justify-content: flex-start;
    padding: 20px 0;
  }
`;

const LogoImg = styled.img`
  height: 24px;
  filter: grayscale(100%);
`;

const HeroSolutions = ({ title = "", subTitle = "", logoSrcs = [], buttonText = "" }) => {
  return (
    <Container>
      <Tag>SOLUTIONS</Tag>
      <Gap height="40px" />
      <Title dangerouslySetInnerHTML={{ __html: title }} />
      <Gap height="20px" />
      <MonoText style={{ color: "#ffffff" }} dangerouslySetInnerHTML={{ __html: subTitle }} />
      <Gap height="40px" />
      <ContactUsModal triggerComponent={<ButtonLink>{buttonText}</ButtonLink>} />
      <Logos>
        {logoSrcs.map((src, i) => {
          let style = {};
          if (i === 2) {
            style = { height: 42 };
          }

          return <LogoImg key={i} src={src} style={style} />;
        })}
      </Logos>
    </Container>
  );
};

export default HeroSolutions;
