import { Gap } from "components/Layout";
import { DownShapeIcon, LeftExtandedShapeIcon } from "components/v3/icons";
import { MonoText, SubTitle } from "components/v3/shared";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  position: relative;
  justify-content: center;
  justify-items: center;
  gap: 0px;
  padding: 120px 0;

  align-content: start;
  position: relative;
  background-color: #1f1f1f;
  color: #ffffff;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    display: none;
  }
`;

const Columns = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;

  width: calc(320px * 2 + 40px * 2);

  & > :last-child {
    border-right: none;
  }
`;

const Column = styled.div`
  display: grid;
  gap: 8px;
  border-right: 1px solid #1f1f1f;
  line-height: 1.5;
`;

const ColumnTitle = styled.div`
  font-family: Alexandria;
  font-size: 19px;
  font-weight: 700;
  line-height: 19.95px;
  text-align: left;

  padding: 0 40px;
`;

const ColumnDescription = styled.div`
  padding: 0 40px;
`;

const BenchmarkText = styled.div`
  font-family: Martian Mono;
  font-size: 97px;
  font-weight: 700;
  line-height: 116.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  background-color: #1473df;
  width: 320px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BenchmarkTextInverse = styled(BenchmarkText)`
  background-color: #ffffff;
  color: #1473df;
`;

const LEFT_STYLE = {
  position: "absolute",
  top: -25.5,
  left: 123,
  fill: "#1473df",
};

const DOWN_BOTTOM_STYLE = {
  position: "absolute",
  bottom: -25.5,
  left: "50%",
  transform: "translateX(-50%)",
  fill: "#1473df",
};

const FAR_RIGHT_STYLE = {
  position: "absolute",
  bottom: 12.5,
  right: 111,
  transform: "rotate(-90deg)",
  fill: "#EBEBEB",
};

const INNER_BOTTOM_LEFT_STYLE = {
  position: "absolute",
  top: 280,
  left: -70,
  transform: "rotate(90deg)",
  fill: "#1473df",
};

const Benchmarks = ({ title = "", description = "", benchmarks = [] }) => {
  return (
    <Container>
      <LeftExtandedShapeIcon style={LEFT_STYLE} />
      <DownShapeIcon style={DOWN_BOTTOM_STYLE} />
      <DownShapeIcon style={FAR_RIGHT_STYLE} />

      <SubTitle style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: title }} />
      <Gap />
      <MonoText
        style={{ width: 740, textAlign: "center", color: "white" }}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <Gap height="100px" />
      <Columns>
        <DownShapeIcon style={INNER_BOTTOM_LEFT_STYLE} />
        <Column>
          <BenchmarkText>
            {benchmarks?.[0]?.value}
            {benchmarks?.[0]?.suffix}
          </BenchmarkText>
          <Gap height="40px" />
          <ColumnTitle dangerouslySetInnerHTML={{ __html: benchmarks?.[0]?.title }} />
          <ColumnDescription>{benchmarks?.[0]?.description}</ColumnDescription>
        </Column>

        <Column>
          <ColumnTitle dangerouslySetInnerHTML={{ __html: benchmarks?.[1]?.title }} />
          <ColumnDescription>{benchmarks?.[1]?.description}</ColumnDescription>
          <Gap height="40px" />
          <BenchmarkTextInverse>
            {benchmarks?.[1]?.value}
            {benchmarks?.[1]?.suffix}
          </BenchmarkTextInverse>
        </Column>
      </Columns>
    </Container>
  );
};

export default Benchmarks;
