import { Gap } from "components/Layout";
import { LinkedInIcon } from "components/v3/icons";
import { MonoText, SubTitle } from "components/v3/shared";
import styled from "styled-components";

const Container = styled.div`
  background-color: #ffffff;
  display: grid;
  justify-content: center;
  justify-items: center;
  gap: 0px;
  padding: 120px 0;
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 115px;

  & > :last-child {
    border-right: none;
  }
`;

const Column = styled.div`
  display: grid;
  line-height: 1.5;
  img {
    width: 236px;
    height: 236px;
    object-fit: cover;
    filter: grayscale(100%);
  }
`;

const ColumnTitle = styled.div`
  font-family: Martian Mono;
  font-size: 19px;
  font-weight: 400;
  text-align: left;
`;

const ColumnSubTitle = styled.div`
  font-family: Martian Mono;
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const StyledLinkedInIcon = styled(LinkedInIcon)`
  fill: #cecece;
  :hover {
    fill: #157fe3;
  }
`;

const OurFoundersV3 = ({ title = "", description = "", messages = [] }) => {
  return (
    <Container>
      <SubTitle style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: title }} />
      <Gap />
      <MonoText style={{ width: 740, textAlign: "center" }} dangerouslySetInnerHTML={{ __html: description }} />
      <Gap height="160px" />
      <Columns>
        {messages?.map((message, i) => (
          <Column key={i}>
            {message.icon}
            <Gap height="40px" />
            <ColumnTitle dangerouslySetInnerHTML={{ __html: message?.title }} />
            <Gap height="10px" />
            <ColumnSubTitle dangerouslySetInnerHTML={{ __html: message?.subTitle }} />
            <Gap />
            <div dangerouslySetInnerHTML={{ __html: message?.text }} />
            <Gap />
            <a href={message?.linkedInUrl} target="_blank">
              <StyledLinkedInIcon />
            </a>
          </Column>
        ))}
      </Columns>
    </Container>
  );
};

export default OurFoundersV3;
