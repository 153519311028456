import { Gap } from "components/Layout";
import { ButtonLink, MonoText, SubTitle, Tag, Title } from "components/v3/shared";
import styled from "styled-components";

const Container = styled.div`
  display: grid;

  align-items: center;
  align-content: center;
  justify-content: center;

  padding: 120px 150px;
  background-color: #eaeaea;
`;

const Content = styled.div`
  width: 940px;
`;

const Papers = styled.div``;

const Paper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  align-content: center;
  align-items: center;
  padding: 40px 0;
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #000;
`;

const PaperTitle = styled.div`
  font-family: Martian Mono;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const PapersSectionV3 = ({ title, papers = [] }) => {
  return (
    <Container>
      <Content>
        <SubTitle style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: title }} />
        <Gap height="40px" />
        <Papers>
          <HorizontalLine />
          {papers.map((paper, index) => (
            <>
              <Paper key={index}>
                <div>
                  <PaperTitle>{paper?.title}</PaperTitle>
                  <Gap height="10px" />
                  <div>{paper?.description}</div>
                </div>
                <ButtonLink href={paper?.url} target="_blank" rel="noopener noreferrer">
                  Download paper
                </ButtonLink>
              </Paper>
              <HorizontalLine />
            </>
          ))}
        </Papers>
      </Content>
    </Container>
  );
};

export default PapersSectionV3;
