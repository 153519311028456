import { BLOG0_TEXT } from "api/backend/blog0";
import { BLOG1_TEXT } from "api/backend/blog1";
import { BLOG2_TEXT } from "api/backend/blog2";

export const FEATURED_ID = 0;

const BLOG_0 = {
  id: 0,
  thumbnailSrc: "/images/blogs/liquid-bz.png",
  title: "Liquidnet and Boltzbit Transform Bond Trading",
  subTitle: "Press Release",
  author: "BOLTZBIT TEAM",
  description:
    "Liquidnet, a leading technology-driven agency execution specialist, today announced its partnership with cutting edge, GenAI startup Boltzbit to optimise its Fixed Income primary markets workflow.",
  dateStr: "2023-10-10",
  tags: ["AI", "LLM", "Custom AI"],
  contentBlocks: [
    {
      text: BLOG0_TEXT,
    },
  ],
};

const BLOG_1 = {
  id: 1,
  thumbnailSrc: "/images/blogs/innovate-uk.jpg",
  title: "Boltzbit Kicks Off Innovate UK Project",
  description:
    "We're thrilled to announce the beginning of an exciting new chapter at Boltzbit! Starting April 1st, Boltzbit has embarked on a groundbreaking project, funded by Innovate UK Partnerships, aimed at revolutionizing business analytics and workflow automation. This milestone marks a significant step forward in our mission to innovate and deliver cutting-edge solutions for businesses worldwide.",
  dateStr: "2023-04-03",
  tags: ["AI", "LLM", "Custom AI"],
  author: "BOLTZBIT TEAM",
  contentBlocks: [
    {
      text: BLOG1_TEXT,
    },
  ],
};

const BLOG_2 = {
  id: 2,
  thumbnailSrc: "/images/blogs/fin-tech-lab.jpg",
  title: "Boltzbit Selected as One of the FinTechs for the FinTech Innovation Lab London 2024",
  description:
    "We are thrilled to announce that Boltzbit has been chosen as one of the 15 new FinTechs for the FinTech Innovation Lab London 2024. This prestigious program, run by the FinTech Innovation Lab, recognizes innovative companies that are transforming the financial industry through cutting-edge technology.",
  dateStr: "2023-10-10",
  tags: ["AI", "LLM", "Custom AI"],
  author: "BOLTZBIT TEAM",
  contentBlocks: [
    {
      text: BLOG2_TEXT,
    },
  ],
};

export const BLOGS = [BLOG_0, BLOG_1, BLOG_2];
