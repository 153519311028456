import ApiHero from "components/ApiHero";
import FooterV3 from "components/FooterV3";
import NavFloating from "components/NavFloating";
import { BGS } from "pages/product-v3";

import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { uuidv4 } from "utils/common";

const SECTIONS = [
  {
    render: () => <NavFloating key={uuidv4()} />,
  },
  {
    render: props => <ApiHero key={uuidv4()} {...props} />,
    props: {
      title: "Boltzflow API",
      description:
        "We give answers you can trust. Deliver traceable, accurate insights with full control over your data analysis.",
      modelsTitle: "Our models",
      models: [
        {
          title: "Flow LLM v1",
          description: "High inteliigence flagship model.",
          features: [
            { title: "Text and vision" },
            { title: "128k context length" },
            // { title: "Input: $5 | Output: $15 per 1M tokens" },
          ],
        },
        {
          title: "Flow LLM mini",
          description: "Affordable model for lightweight tasks.",
          features: [
            { title: "Text" },
            { title: "32k context length" },
            // { title: "Input: $5 | Output: $15 per 1M tokens" },
          ],
        },
      ],
      sectionsTitle: "APIs we offer",
      smallSections: [
        {
          title: "Chat API",
          navTitle: "Chat API",
          imageSrc: "/images/api/chat-hr.png",
          backgroundImgSrc: BGS[4],
          description:
            "Our chat API is a powerful tool for building conversational interfaces. Utilising internet and proprietary data sources.",
        },
        {
          title: "Analytics API",
          navTitle: "Analytics API",
          imageSrc: "/images/api/analytics-code.png",
          backgroundImgSrc: BGS[5],
          description:
            "Our analytics API can interrogate data and draw insights from multiple relational and JSON databases.",
        },
        {
          title: "Workflow API",
          navTitle: "Workflow API",
          imageSrc: "/images/api/workflow-hr.png",
          backgroundImgSrc: BGS[6],
          description:
            "Automate your workflows using natural language instructions, to send out emails, generate reports, and more.",
        },
        {
          title: "Data Extraction API",
          navTitle: "Data Extraction API",
          imageSrc: "/images/api/extract-hr.png",
          backgroundImgSrc: BGS[7],
          description:
            "Powerful OCR combined with Large Language Models for structured content extraction from PDF, Excel, PowerPoint, Word and websites.",
        },
        {
          title: "Fine-tuning API",
          navTitle: "Fine-tuning API",
          imageSrc: "/images/api/fine-tuning.png",
          backgroundImgSrc: BGS[0],
          description:
            "Fine tuning your own Large Language Model for your own specific data extraction, workflow automation and analytics tasks.",
        },
        // {
        //   title: "Word Document API",
        //   navTitle: "Word Document API",
        //   imageSrc: "/images/product/chat.png",
        //   backgroundImgSrc: "/images/api/squares-background.jpeg",
        //   description: "Powerful OCR and structured content extraction API.",
        // },
      ],
    },
  },
  {
    render: props => <FooterV3 />,
  },
];

const DesktopContainer = styled.div`
  font-family: Alexandria;
  font-size: 16px;
  font-weight: 300;

  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Container = styled.div``;

const ApiPageV3 = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("token") === "bzabc") {
      localStorage.setItem("token", searchParams.get("token"));
    }
  }, [searchParams.get("token")]);

  const token = searchParams?.get("token") || localStorage.getItem("token");

  // if (token !== "bzabc") {
  //   return null;
  // }

  return (
    <Container>
      <DesktopContainer>{SECTIONS?.map(({ render, props }) => render(props))}</DesktopContainer>
    </Container>
  );
};

export default ApiPageV3;
