import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";

import ButtonDark from "components/ButtonV1";
import Modal from "components/ui/Modal";
import { postContactUsDetails } from "api/services/marketingService";
import { COLOR2 } from "pages/main-v2";
import { ButtonLink } from "components/v3/shared";

const ModalContent = styled.div`
  color: black;
  padding: 10px;
  border: 1px solid #ffffff44;
  box-shadow: 0px 0px 20px 4px #ffffff11;
  /* border-radius: 10px; */
  /* background-image: linear-gradient(
    135deg,
    ${props => props.theme.color.secondary},
    ${props => props.theme.color.primary}
  ); */

  font-size: 16px;
  width: 90vw;
  display: grid;
  gap: 12px;
  padding: 28px;
  border-radius: 4px;

  @media (min-width: 1240px) {
    width: 500px;
  }
`;

const Title = styled.div`
  font-size: 28px;
  font-family: "Alexandria";
`;

const Input = styled.input`
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
  background-color: transparent;
  color: #1f1f1f;
  max-width: 365px;
  font-family: Martian Mono;
  outline: none;
  margin-bottom: 20px;
  :focus {
    border-bottom: 1px solid #1473df;
  }
`;

const InputTitle = styled.div`
  font-size: 16px;
`;

const TextArea = styled.textarea`
  padding: 4px 8px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #eaeaea;
  background-color: transparent;
  margin-bottom: 20px;
  :focus {
    border: 1px solid ${COLOR2};
  }
  font-size: 16px;

  height: 80px;
  resize: none;
`;

const ErrorMsg = styled.div`
  color: #ff000094;
  height: 20px;
  font-size: 14px;
`;

const SuccessMsg = styled.div`
  min-height: 48px;
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
`;

const BigLink = styled.a`
  width: max-content;
  color: ${COLOR2};
  font-size: inherit;
`;

const FIELDS = [
  {
    name: "name",
    label: "Your name *",
    placeholder: "",
  },
  {
    name: "email",
    label: "Your email *",
    placeholder: "",
  },
  // {
  //   name: "company",
  //   label: "Your Company",
  //   placeholder: "Boltzbit",
  // },
  {
    name: "role",
    label: "Job Role",
    placeholder: "Hedge Fund Manager",
  },
  {
    name: "message",
    label: "Your message",
    type: "textarea",
    placeholder: "Your message here",
  },
];

const ContactUsModal = ({
  triggerComponent = <ButtonDark>Contact us</ButtonDark>,
  fieldNames = ["name", "email"],
  isShowingCalendarLink = false,
  product = "launch",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [payload, setPayload] = useState({
    name: "",
    email: "",
    message: "",
    role: "",
  });
  const [hasBeenSent, setHasBeenSent] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setPayload({
        name: "",
        email: "",
        message: "",
        role: "",
      });
      setErrorMsg("");
      setHasBeenSent(false);
    }
  }, [isOpen]);

  return (
    <>
      <div style={{ zIndex: 1 }} onClick={() => setIsOpen(true)}>
        {triggerComponent}
      </div>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <Title style={{ justifySelf: "center", marginBottom: "20px" }}>Contact us</Title>
          {FIELDS?.filter(field => fieldNames?.includes(field?.name)).map(field => (
            <Fragment key={field?.name}>
              <InputTitle>{field.label}</InputTitle>
              {field.type === "textarea" ? (
                <TextArea
                  value={payload[field.name]}
                  onChange={e => setPayload({ ...payload, [field.name]: e.target.value })}
                  placeholder={field.placeholder}
                />
              ) : (
                <Input
                  value={payload[field.name]}
                  onChange={e => setPayload({ ...payload, [field.name]: e.target.value })}
                  placeholder={field.placeholder}
                />
              )}
            </Fragment>
          ))}

          <ErrorMsg>{errorMsg}</ErrorMsg>
          {hasBeenSent ? (
            <SuccessMsg
              style={{
                justifySelf: "center",
              }}
            >
              Thank you, we'll be in touch.
              {/* {isShowingCalendarLink && (
                <span style={{ fontSize: "inherit" }}>
                  {" "}
                  You can also{" "}
                  <BigLink href="https://calendly.com/jess-wilkinson-boltzbit/get-to-know-boltzbit" target="_blank">
                    schedule a call with us.
                  </BigLink>
                </span>
              )} */}
            </SuccessMsg>
          ) : (
            <ButtonLink
              disabled={isSending || !payload?.email || !payload?.name}
              onClick={async () => {
                setIsSending(true);
                setErrorMsg("");
                const { name, email } = payload;
                const { error } = await postContactUsDetails({
                  firstName: name?.split(" ")?.[0] || "",
                  lastName: name?.split(" ")?.[1] || "",
                  email,
                  message: payload?.message,
                  company: "",
                  role: payload?.role,
                  industry: "",
                  phone: "",
                  country: "",
                  product,
                });
                if (error) {
                  setErrorMsg(error?.message || "There was an error. Please try again.");
                  setIsSending(false);
                  return;
                }

                setIsSending(false);
                setHasBeenSent(true);
              }}
              style={{
                // width: "100px",
                // height: "40px",
                // fontSize: "16px",
                justifySelf: "center",
                marginTop: "20px",
              }}
            >
              Send
            </ButtonLink>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ContactUsModal;
