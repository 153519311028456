import { Gap } from "components/Layout";
import { DownShapeIcon, LeftExtandedShapeIcon, RightShapeIcon } from "components/v3/icons";
import { SubTitle } from "components/v3/shared";
import { transform } from "lodash";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  background-color: #eaeaea;
  color: #1f1f1f;

  display: grid;
  justify-content: center;
  align-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  padding: 120px 150px;
`;

const LeftColumn = styled.div`
  width: 300px;
  line-height: 1.2;
`;

const RightColumn = styled.div`
  position: relative;
  video {
    height: 250px;
  }
`;

const OptionContent = styled.div`
  font-size: 14px;
  line-height: 1.2;
`;

const Selectors = styled.div`
  align-self: bottom;
  background-color: #157fe3;
  padding: 60px;
`;

const SelectorTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Martian Mono;
  color: ${props => (props.selected ? "#157FE3" : "#ffffff")};
`;

const SelectorOption = styled.div`
  border-top: 1px solid white;
  font-size: 19px;
  font-weight: 300;
  padding: 20px 15px;
  cursor: pointer;
  transition: background-color 0.2s;

  display: grid;
  gap: 20px;
  align-items: center;
  background-color: ${props => (props.selected ? "#ffffff" : "transparent")};

  svg {
    fill: ${props => (props.selected ? "#157FE3" : "#ffffff")};
  }
  :last-child {
    border-bottom: 1px solid white;
  }
`;

const getArrowStyle = selected => {
  if (selected) {
    return {
      transform: "rotate(90deg)",
      height: 20,
      width: 14,
    };
  }
  return {
    height: 20,
    width: 14,
  };
};

const DOWN_STYLE = {
  position: "absolute",
  top: 0,
  left: "50%",
  transform: "translateX(-50%)",
};

const LEFT_STYLE = {
  position: "absolute",
  bottom: -26,
  right: 223,
};

const SelectorSectionExpandingV3 = ({ title, description, options }) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

  return (
    <Container>
      <DownShapeIcon style={DOWN_STYLE} />
      <LeftExtandedShapeIcon style={LEFT_STYLE} />
      <LeftColumn>
        <SubTitle>{title}</SubTitle>
        <Gap height="10px" />
        <div>{description}</div>
      </LeftColumn>
      <RightColumn>
        <Selectors>
          {options.map((option, i) => (
            <SelectorOption selected={i === selectedOptionIndex} key={i} onClick={() => setSelectedOptionIndex(i)}>
              <SelectorTitle selected={i === selectedOptionIndex}>
                {option.title}
                <RightShapeIcon style={getArrowStyle(i === selectedOptionIndex)} />
              </SelectorTitle>
              {i === selectedOptionIndex && <OptionContent>{option.content}</OptionContent>}
            </SelectorOption>
          ))}
        </Selectors>
      </RightColumn>
    </Container>
  );
};

export default SelectorSectionExpandingV3;
