export const PRIVACY_HTML_STR = `
<br />
<p class="c43" id="h.gjdgxs">
  <span class="c35">Boltzbit GenAI Solution </span><span class="c8 c35">Data Processing Agreement</span>
</p>
<div class="c15 c5">
  <span class="c14 c32"
    >We are Boltzbit Limited (CRN: 12398587).<br />Our registered office is at: York House, 221 Pentonville Road, N1
    9UZ, London, UK.<br />If you have any questions about this privacy policy, including any requests to exercise your
    legal rights, please contact us at </span
  ><span class="c12"><a class="c20" href="mailto:privacy@boltzbit.com">privacy@boltzbit.com</a></span
  ><span class="c2">.</span>
</div>
<p class="c6"><span class="c22 c8"></span></p>
<br />
<div class="c5 c15" id="h.rxqr8gdn3s2w">
  <span class="c14 c32"
    >We are registered with the ICO, the UK&#39;s data protection regulator, you can find our details </span
  ><span class="c12"
    ><a
      class="c20"
      href="https://www.google.com/url?q=https://ico.org.uk/ESDWebPages/Entry/ZB032486&amp;sa=D&amp;source=editors&amp;ust=1734021436902285&amp;usg=AOvVaw2xgubBkA1NH5VGrC-Vc1C-"
      >here</a
    ></span
  ><span class="c14 c32">.</span>
</div>
<br />
<div class="c26 c41" id="h.9zutnv25rx1m">
  <span class="c0">During the course of providing Services (&ldquo;</span><span class="c0">Services</span
  ><span class="c0"
    >&rdquo;) under the Terms of Service and any Supplementary Terms and Policies available under
    <a href="https://boltzbit.com/flow/terms">https://boltzbit.com/flow/terms</a> applicable to the Customer&rsquo;s use of the Services (together the </span
  ><span class="c0">&ldquo;Terms&rdquo;</span><span class="c0">)</span><span class="c0">&nbsp;</span
  ><span class="c0">Boltzbit Limited (&ldquo;</span><span class="c0">Boltzbit</span
  ><span class="c0"
    >&rdquo;) may obtain, access or otherwise Process information that identifies an individual or relates to an
    identifiable individual (&ldquo;</span
  ><span class="c0">Personal Information</span><span class="c0">&rdquo;) from, or on behalf of you (&ldquo;</span
  ><span class="c0">Customer</span
  ><span class="c0"
    >&rdquo;). Boltzbit agrees to protect all such Personal Information, Emails, Private Documents in any digital format
    as detailed in this Data Processing Agreement.</span
  >
</div>
<br />
<div class="c26">
  <span class="c3">Compliance with Law.</span
  ><span class="c0 c8"
    >&nbsp;Boltzbit will comply with all applicable laws relating to the protection of Personal Information and </span
  ><span class="c0">Sensitive Data </span
  ><span class="c0 c8">that apply with respect to Boltzbit&rsquo;s handling of Personal Information and </span
  ><span class="c0">Sensitive Data</span><span class="c0 c8">&nbsp;</span
  ><span class="c0"
    >uploaded from you or third party APIs authorised by you, including, but not limited to Google APIs, Microsoft
    Office 365 APIs</span
  ><span class="c1 c21">.</span>
</div>
<br />
<p class="c37"><span class="c14 c5 c33">HOW DO WE USE YOUR DATA?</span></p>
<div class="c26">
  <span class="c3">Limitations on Use</span
  ><span class="c0 c8">. Boltzbit shall not be entitled to use Personal Information and </span
  ><span class="c0">Sensitive Data</span
  ><span class="c0 c8">&nbsp;for its own purposes, and will only Process Personal Information and </span
  ><span class="c0">Sensitive Data</span
  ><span class="c0 c8"
    >&nbsp;on Customer&rsquo;s behalf, in the context of its direct business relationship with Customer and in
    accordance with Customer&rsquo;s instructions as documented in the Terms and this Data Processing Agreement. If
    Boltzbit is required to Process Personal Information and </span
  ><span class="c0">Sensitive Data</span
  ><span class="c0 c8"
    >&nbsp;in compliance with a law of the European Union or a Member State to which Boltzbit is subject, it will inform
    Company of such legal requirement prior to such processing, unless a law of the European Union or a Member State to
    which Boltzbit is subject prohibits it from doing so. Boltzbit will not otherwise Process Personal Information. The
    duration of the Processing will be the same as the duration of the Terms, except as otherwise agreed to in writing
    by the parties. &ldquo;</span
  ><span class="c3">Process</span><span class="c0 c8">&rdquo; or &ldquo;</span><span class="c3">Processing</span
  ><span class="c1 c21"
    >&rdquo; means the collection, recording, organization, structuring, alteration, access, disclosure, copying,
    transfer, storage, retention, deletion, combination, restriction, adaptation, retrieval, consultation, destruction,
    disposal, sale, sharing or other use of Personal Information.</span
  >
</div>
<ol class="c13 lst-kix_list_2-0 start" start="1">
  <li class="c5 c18 c19 li-bullet-0">
    <span class="c14"
      >When you create an account with us we will collect your name, e-mail address, name of your employer and record
      your preferences to provide you with our service. We process your data in this way to perform the contract in
      place between us.</span
    >
  </li>
  <li class="c5 c18 c36 li-bullet-0">
    <span class="c14"
      >When you use our Service we collect information to provide you with the GenAI data extraction and management
      services as set out below. If you have an account with us, we handle your data in this way to perform the contract
      in place between us. If you don&#39;t have an account with us, we handle your data in this way in accordance with
      our legitimate interest to arrange meetings for our registered users:</span
    >
  </li>
</ol>
<p class="c4 c5"><span class="c2">&nbsp; &nbsp; &nbsp; &nbsp; </span></p>
<table class="c34">
  <tr class="c29">
    <td class="c39 c5" colspan="1" rowspan="1">
      <p class="c4"><span class="c2">Sr.No</span></p>
    </td>
    <td class="c16 c5" colspan="1" rowspan="1">
      <p class="c4"><span class="c2">Description of information collected</span></p>
    </td>
    <td class="c11 c5" colspan="1" rowspan="1">
      <p class="c4">
        <span class="c2">How we use this information to provide you the GenAI solution service</span>
      </p>
    </td>
  </tr>
  <tr class="c25">
    <td class="c39 c5" colspan="1" rowspan="1">
      <p class="c4"><span class="c2">a.</span></p>
    </td>
    <td class="c16 c5" colspan="1" rowspan="1">
      <p class="c4">
        <span class="c2"
          >Documents (PDF, excel, PPT, chat, emails, voice records) uploaded to the solution platform</span
        >
      </p>
    </td>
    <td class="c5 c11" colspan="1" rowspan="1">
      <p class="c4">
        <span class="c2"
          >for using as the sources documents as the input to Generative models to extract information or answering
          questions</span
        >
      </p>
    </td>
  </tr>
  <tr class="c30">
    <td class="c39 c5" colspan="1" rowspan="1">
      <p class="c4"><span class="c2">b.</span></p>
    </td>
    <td class="c16 c5" colspan="1" rowspan="1">
      <p class="c4">
        <span class="c2">Chat conversation the user typed in our chatbot interface on the solution platform</span>
      </p>
    </td>
    <td class="c11 c5" colspan="1" rowspan="1">
      <p class="c4"><span class="c2">To perform question and answering on the provided documents</span></p>
    </td>
  </tr>
  <tr class="c29">
    <td class="c39 c5" colspan="1" rowspan="1">
      <p class="c4"><span class="c2">c.</span></p>
    </td>
    <td class="c16 c5" colspan="1" rowspan="1">
      <p class="c4">
        <span class="c2"
          >Tabular data in excel, csv or other file format uploaded to a self-customised GenAI Solution App on the
          solution platform</span
        >
      </p>
    </td>
    <td class="c11 c5" colspan="1" rowspan="1">
      <p class="c4"><span class="c2">To enable data analytics and workflow automation</span></p>
    </td>
  </tr>
  <tr class="c25">
    <td class="c39 c5" colspan="1" rowspan="1">
      <p class="c4"><span class="c2">d.</span></p>
    </td>
    <td class="c16 c5" colspan="1" rowspan="1">
      <p class="c4">
        <span class="c2"
          >User&rsquo;s editing of questions, change of the answer from Generative AI models or feedbacks including
          labelling and positive/negative feedbacks to the answers</span
        >
      </p>
    </td>
    <td class="c11 c5" colspan="1" rowspan="1">
      <p class="c4">
        <span class="c2">To fine-tune the model&rsquo;s output to improve its accuracy and performance.</span>
      </p>
    </td>
  </tr>
  <tr class="c23">
    <td class="c5 c39" colspan="1" rowspan="1">
      <p class="c4"><span class="c2">e.</span></p>
    </td>
    <td class="c5 c16" colspan="1" rowspan="1">
      <p class="c4"><span class="c2">All derivative knowledge from the uploaded asset above</span></p>
    </td>
    <td class="c11 c5" colspan="1" rowspan="1">
      <p class="c4"><span class="c2">for model training, user feedbacks</span></p>
    </td>
  </tr>
  <tr class="c30">
    <td class="c39 c5" colspan="1" rowspan="1">
      <p class="c4"><span class="c2">f.</span></p>
    </td>
    <td class="c16 c5" colspan="1" rowspan="1">
      <p class="c4"><span class="c2">Name of your employer</span></p>
    </td>
    <td class="c11 c5" colspan="1" rowspan="1">
      <p class="c4">
        <span class="c2">We will only collect this data where you have a corporate subscription</span>
      </p>
    </td>
  </tr>
</table>
<p class="c6 c18"><span class="c2"></span></p>
<ol class="c13 lst-kix_list_2-0" start="3">
  <li class="c19 c5 c18 li-bullet-0">
    <span class="c14"
      >When you contact us either by phone, email or via social media we will usually collect your name, gender, contact
      details, social media handle and any other personal data that forms part of your message to us because it&rsquo;s
      in our legitimate interest to make sure we can properly respond to your query.</span
    >
  </li>
  <li class="c36 c5 c18 li-bullet-0">
    <span class="c14"
      >Technical information when you use Generative AI Solution. When you consent to our use of cookies, we collect
      information about how you use our website. We use this information to improve our website and to better understand
      how people use it. More detail on the information we collect and how we do this is set out in our cookie
      policy.</span
    >
  </li>
</ol>
<br />
<p class="c37 c18"><span class="c14 c33 c21">HOW DO WE PROTECT YOUR DATA?</span></p>
<ol class="c13 lst-kix_list_2-1 start" start="1">
  <li class="c7">
    <div style="display: inline">
      <span class="c3">Description of Processing. </span
      ><span class="c0 c8">The Services provided by Boltzbit (described in more detail at </span
      ><a class="c0" href="https://boltzbit.com/flow/terms" target="_blank">https://boltzbit.com/flow/terms</a><span class="c0 c8">) comprise of the &ldquo;</span
      ><span class="c3">Customer Repository</span
      ><span class="c0 c8"
        >&rdquo; i.e an online area on the Boltzbit platform designated to the Customer where Customer can </span
      ><span class="c0">customise</span><span class="c0 c8">&nbsp;and deploy </span
      ><span class="c0">large language </span
      ><span class="c0 c8">models, datasets, and machine learning applications and related services. To </span
      ><span class="c0">customize customer&rsquo;s</span
      ><span class="c1 c21"
        >&nbsp;large language models and otherwise make use of the Services within Customer&rsquo;s Repository, Customer
        may upload its own Personal Information to the platform hosted by Boltzbit. Boltzbit will use the Personal
        Information solely for the purpose of delivering the Services as described in the Terms. The categories of
        individuals and the types of Personal Information are determined by the Customer and depend on the specific
        information that is uploaded by the Customer to the Customer Repository.</span
      >
    </div>
  </li>
  <li class="c7">
    <div style="display: inline">
      <span class="c3">Confidentiality</span
      ><span class="c1 c21"
        >. Boltzbit will hold Personal Information and Sensitive Data in strict confidence and impose confidentiality
        obligations on Boltzbit personnel who will be provided access to, or will otherwise Process, Personal
        Information, including requiring personnel to protect all Personal Information in accordance with the
        requirements of this Data Processing Agreement (including during the term of their employment or engagement and
        thereafter). In addition, Boltzbit will take steps to ensure that any individual acting under its authority who
        has access to Personal Information does not Process such Personal Information except on instructions of Company,
        unless such individual is required to do so by a law of the European Union or a Member State.</span
      >
    </div>
  </li>
  <li class="c7">
    <div style="display: inline">
      <span class="c3">Information Security Program</span
      ><span class="c0 c8"
        >. Boltzbit will implement, maintain, monitor and, where necessary, update a comprehensive written information
        security program that contains appropriate administrative, technical, and physical safeguards to protect
        Personal Information against anticipated threats or hazards to its security, confidentiality or integrity. As
        part of its information security program, Boltzbit will maintain appropriate access controls, including, but not
        limited to, data </span
      ><span class="c0">encryption</span
      ><span class="c1 c21"
        >&nbsp;for data transit and storage, limiting access to Personal Information and Sensitive Data to the minimum
        number of Boltzbit personnel who require such access in order to provide the Services to Customer and providing
        those personnel who have access to Personal Information with appropriate training relating to information
        security.</span
      >
    </div>
  </li>
  <li class="c7">
    <div style="display: inline">
      <span class="c3">Security Incidents. </span
      ><span class="c0 c8"
        >Boltzbit will promptly notify Customer if Boltzbit has reason to believe that there has been any accidental or
        unauthorized access, acquisition, use, modification, disclosure, loss, destruction of, or damage to Personal
        Information, or any other unauthorized Processing of Personal Information and Sensitive Data (&ldquo;</span
      ><span class="c3">Security Incident</span
      ><span class="c1 c21"
        >&rdquo;). In the event of any Security Incident, Boltzbit will cooperate fully with Customer to limit the
        unauthorized access, disclosure or use of Personal Information, seek the return of any such Personal
        Information, and assist in providing notice relating to the Security Incident to individuals or third parties if
        Customer requests.
      </span>
    </div>
  </li>
  <li class="c7">
    <div style="display: inline">
      <span class="c3">Cross-Border Transfers. </span
      ><span class="c0 c8"
        >In connection with the performance of the Terms, Boltzbit may transfer Personal Information to various
        locations, which may include locations both inside and outside of the European Economic Area
        (&ldquo;EEA&rdquo;). Boltzbit agrees to execute such transfers only after ensuring that the requirements of
        Chapter 5 of the GDPR are met. This may include, as applicable, putting in place the 2021 EU Standard
        Contractual Clauses (Module 2 (Controller to Processor), and/or Module 3 (Processor-to-Subprocessor), (EU)
        2021/914, available at </span
      ><span class="c0 c8 c38"
        ><a
          href="https://www.google.com/url?q=https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?uri%3DCELEX%253A32021D0914%26locale%3Den&amp;sa=D&amp;source=editors&amp;ust=1734021436910614&amp;usg=AOvVaw3QLQlVa0UJjG_mYUO9zwpA"
          >https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?uri=CELEX%3A32021D0914&amp;locale=en</a
        ></span
      ><span class="c1 c21">). </span>
    </div>
  </li>
  <li class="c7">
    <div style="display: inline">
      <span class="c3">Subcontracting</span
      ><span class="c0 c8"
        >. Boltzbit will not disclose or transfer Personal Information to, or allow access to Personal Information by
        (each, a &ldquo;</span
      ><span class="c3">Disclosure</span
      ><span class="c0 c8"
        >&rdquo;) any third party without Customer&rsquo;s express prior consent; provided, however, that Boltzbit may
        Disclose Personal Information to its affiliates and subcontractors for purposes of providing the Services to
        Customer, subject to the following conditions: (a) Boltzbit will maintain a list of the affiliates and
        subcontractors to which it makes such Disclosures and will provide this list to Customer upon Customer&rsquo;s
        request; (b) Boltzbit will provide Customer at least 30 days&rsquo; prior notice of the addition of any
        affiliate or subcontractor to this list and the opportunity to object to such addition(s); and (c) if Customer
        makes such an objection on reasonable grounds and Boltzbit is unable to modify the Services to prevent
        Disclosure of Personal Information to the additional affiliate or subcontractor, Customer will have the right to
        terminate the relevant Processing. Boltzbit will, prior to any Disclosure, enter into an agreement with such
        third party that binds the third party to the same obligations and awards Company the same rights contained in
        this Data Processing Agreement with regard to such third parties. Such agreement will be provided to Customer
        promptly upon request. Boltzbit will be liable for all actions by such third parties with respect to the
        Disclosure.</span
      ><span class="c3">&nbsp;</span>
    </div>
  </li>
  <li class="c7">
    <div style="display: inline">
      <span class="c3">Requests or Complaints from Individuals</span
      ><span class="c1 c21"
        >. Boltzbit will promptly notify Customer, unless specifically prohibited by laws applicable to Boltzbit, if
        Boltzbit receives: (i) any requests from an individual with respect to Personal Information Processed, including
        but not limited to opt-out requests, requests for access and/or rectification, erasure, restriction, requests
        for data portability, and all similar requests; or (ii) any complaint relating to the Processing of Personal
        Information, including allegations that the Processing infringes on an individual&rsquo;s rights. Boltzbit will
        not respond to any such request or complaint unless expressly authorized to do so by Customer. Boltzbit will
        cooperate with Customer with respect to any action taken relating to an individual&rsquo;s request or complaint
        and will seek to implement appropriate processes (including technical and organizational measures) to assist
        Customer in responding to such requests or complaints. Boltzbit will promptly and securely delete or destroy any
        Personal Information pertaining to an individual identified by Customer where such information is within
        Boltzbit&rsquo;s possession or control. If applicable, Boltzbit will direct any affiliate or subprocessor that
        Processes Personal Information related to the identified individual to promptly and securely delete or destroy
        such Personal Information. Boltzbit will confirm to Customer that it has complied with its obligations under
        this section.
      </span>
    </div>
  </li>
  <li class="c7">
    <div style="display: inline">
      <span class="c3">Disclosure Requests</span><span class="c0 c8">.</span><span class="c3">&nbsp;</span
      ><span class="c0 c8"
        >If Boltzbit receives any order, demand, warrant, or any other document requesting or purporting to compel the
        production of Personal Information (including, for example, by oral questions, interrogatories, requests for
        information or documents in legal proceedings, subpoenas, civil investigative demands or other similar
        processes) (&ldquo;</span
      ><span class="c3">Disclosure Request</span
      ><span class="c1 c21"
        >&rdquo;), Boltzbit will immediately notify Customer (except to the extent otherwise required by laws applicable
        to Boltzbit). If the Disclosure Request is not legally valid and binding, Boltzbit will not respond. If a
        Disclosure Request is legally valid and binding, Boltzbit will provide Customer at least 48 hours&rsquo; notice
        prior to the required disclosure, so that Customer may, at its own expense, exercise such rights as it may have
        under applicable law to prevent or limit such disclosure. Notwithstanding the foregoing, Boltzbit will exercise
        commercially reasonable efforts to prevent and limit any such disclosure and to otherwise preserve the
        confidentiality of Personal Information and will cooperate with Customer with respect to any action taken with
        respect to such request, complaint, order or other document, including to obtain an appropriate protective order
        or other reliable assurance that confidential treatment will be accorded to Personal Information.
      </span>
    </div>
  </li>
  <li class="c7">
    <div style="display: inline">
      <span class="c3">Audit</span
      ><span class="c1 c21"
        >. Boltzbit will provide to Customer, its authorized representatives, and such independent inspection body as
        Customer may appoint, on reasonable notice: (i) access to Boltzbit&rsquo;s information, processing premises, and
        records; (ii) reasonable assistance and cooperation of Boltzbit&rsquo;s relevant staff; and (iii) reasonable
        facilities at Boltzbit&rsquo;s premises for the purpose of auditing Boltzbit&rsquo;s compliance with its
        obligations under this Data Processing Agreement.
      </span>
    </div>
  </li>
  <li class="c7">
    <div style="display: inline">
      <span class="c3">Regulatory Investigations</span
      ><span class="c1 c21"
        >. Upon notice to Boltzbit, Boltzbit will assist and support Customer in the event of an investigation by any
        regulator, including a data protection authority, or similar authority, if and to the extent that such
        investigation relates to Personal Information handled by Boltzbit on behalf of Customer in accordance with this
        Data Processing Agreement. Such assistance will be at Customer&rsquo;s sole expense, except where investigation
        was required due to Boltzbit&rsquo;s acts or omissions, in which case such assistance will be at
        Boltzbit&rsquo;s sole expense.
      </span>
    </div>
  </li>
  <li class="c7">
    <div style="display: inline">
      <span class="c3">Return or Disposal</span
      ><span class="c1 c21"
        >. Upon termination or expiration of this Data Processing Agreement for any reason or upon Customer&rsquo;s
        request, Boltzbit will immediately cease handling Personal Information and will return in a manner and format
        reasonably requested by Customer, or, if specifically directed by Customer, will destroy, any or all Personal
        Information in Boltzbit&rsquo;s possession, power or control, unless it is required to store the Personal
        Information under a law of the European Union or a Member State. Upon request, Boltzbit will provide a written
        certification that Personal Information has been returned or securely destroyed in accordance with this Data
        Processing Agreement.</span
      >
    </div>
  </li>
  <li class="c7">
    <div style="display: inline">
      <span class="c3">Other</span
      ><span class="c0 c8"
        >. Boltzbit will provide relevant information and assistance requested by Customer to demonstrate
        Boltzbit&rsquo;s compliance with its obligations under this Data Processing Agreement and assist Customer in
        meeting its obligations under data protection laws regarding: (i) registration and notification; (ii)
        accountability; (iii) ensuring the security of the Personal Information; and (iv) carrying out privacy and data
        protection impact assessments and related consultations with data protection authorities. Boltzbit will inform
        Customer promptly if Boltzbit believes that any instructions of Customer regarding the Processing of Personal
        Information would violate applicable laws</span
      ><span class="c21 c8">&nbsp;</span
      ><span class="c0 c8"
        >and regulations, including data protection laws, or a change in the applicable laws and regulations is likely
        to have a substantially adverse effect on its ability to comply with its obligations under this Data Processing
        Agreement.</span
      >
    </div>
  </li>
  <li class="c7">
    <div style="display: inline">
      <span class="c3">Adverse Changes</span
      ><span class="c0 c8"
        >. Boltzbit will notify Customer promptly if Boltzbit: (i) has reason to believe that it is unable to comply
        with any of its obligations under this Data Processing Agreement and it cannot cure this inability to comply
        within a reasonable timeframe; or (ii) becomes aware of any circumstances or change in applicable law that is
        likely to prevent it from fulfilling its obligations under this Data Processing Agreement. In the event that
        this Data Processing Agreement, or any actions to be taken or contemplated to be taken in performance of this
        Data Processing Agreement, do not or would not satisfy either party&rsquo;s obligations under the laws
        applicable to each party, the parties will negotiate in good faith upon an appropriate amendment to this Data
        Processing Agreement.
      </span>
    </div>
  </li>
</ol>
<p class="c4 c5"><span class="c33 c31 c14 c8">WHERE IS MY DATA STORED?</span></p>
<p class="c4 c5">
  <span class="c2"
    >We store your data securely in private/cloud-based databases based in the UK, fully managed by us to provide best
    protection. However, if we ever need to transfer your personal information outside of the UK and the EU, we ensure
    it receives additional protection as required by law. To keep this privacy policy as short and easy to understand as
    possible, we haven&rsquo;t set out the specific circumstances when each of these protection measures are used. You
    can contact us at privacy@boltzbit.com for more detail on this.</span
  >
</p>
<p class="c6"><span class="c22 c8"></span></p>
<br />
<p class="c4 c5"><span class="c33 c14 c8 c31">HOW LONG DO WE KEEP YOUR DATA FOR?</span></p>
<p class="c4 c5">
  <span class="c2"
    >We will only retain your personal information for as long as we need it unless we are required to keep it for
    longer to comply with our legal, accounting or regulatory requirements. We delete all of your data immediately as
    requested by you when the service account is terminated.</span
  >
</p>
<p class="c4 c5">
  <span class="c2"
    >In some circumstances we may carefully anonymise your personal data and how our site is used, so that it can no
    longer be associated with you, and we may use this anonymised information indefinitely without notifying you. We use
    this anonymised information to analyse our business and improve it moving forward.</span
  >
</p>
<p class="c6"><span class="c22 c8"></span></p>
<br />
<p class="c4 c5"><span class="c33 c31 c14 c8">WHAT ARE MY RIGHTS UNDER DATA PROTECTION LAWS?</span></p>
<p class="c4 c5">
  <span class="c2">You have various other rights under applicable data protection laws, including the right to:</span>
</p>
<ul class="c13 lst-kix_list_1-0 start">
  <li class="c19 c5 c40 li-bullet-0">
    <span class="c14">access your personal data (also known as a &ldquo;subject access request&rdquo;);</span>
  </li>
  <li class="c9 c5 li-bullet-0">
    <span class="c14">correct incomplete or inaccurate data we hold about you;</span>
  </li>
  <li class="c9 c5 li-bullet-0"><span class="c14">ask us to erase the personal data we hold about you;</span></li>
  <li class="c9 c5 li-bullet-0"><span class="c14">ask us to restrict our handling of your personal data;</span></li>
  <li class="c5 c9 li-bullet-0">
    <span class="c14">ask us to transfer your personal data to a third party; </span>
  </li>
  <li class="c9 c5 li-bullet-0"><span class="c14">object to how we are using your personal data; and</span></li>
  <li class="c5 c24 li-bullet-0">
    <span class="c14">withdraw your consent to us handling your personal data.</span>
  </li>
</ul>
<p class="c4 c5">
  <span class="c14"
    >You also have the right to lodge a complaint with us or the Information Commissioner&#39;s Office, the supervisory
    authority for data protection issues in England and Wales. If you are based outside of England and Wales, you can
    find your relevant supervisory authority </span
  ><span class="c17"
    ><a
      class="c20"
      href="https://www.google.com/url?q=https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm&amp;sa=D&amp;source=editors&amp;ust=1734021436913731&amp;usg=AOvVaw0qC-hp_LZwJMxtohRs-xTT"
      >here</a
    ></span
  ><span class="c2">.</span>
</p>
<p class="c4 c5 c27"><span class="c2"></span></p>
<div class="c26" id="h.30j0zll">
<br />
  <span class="c1 c21"
    >The obligations of Boltzbit under this Data Privacy Agreement will continue for so long as Boltzbit continues to
    have access to, is in possession of or acquires Personal Information and Sensitive Data, even if all agreements
    between Boltzbit and Customer have expired or have been terminated.</span
  >
</div>
<p class="c4 c5 c27"><span class="c2"></span></p>
<p class="c6"><span class="c22 c8"></span></p>
<br />
<p class="c4 c5">
  <span class="c2"
    >Please keep in mind that privacy law is complicated, and these rights will not always be available to you all of
    the time.</span
  >
</p>
<p class="c6"><span class="c22 c8"></span></p>
<p class="c6"><span class="c1"></span></p>
<br />
<div class="c33">QUESTIONS, COMMENTS AND MORE DETAIL</div>
<div>Your feedback and suggestions on this notice are welcome - please reach out to us by email at <a href="mailto:privacy@boltzbit.com">privacy@boltzbit.com</a>.</div>
`;
