import { Gap } from "components/Layout";
import { DownShapeIcon, LShapeIcon, LeftExtandedShapeIcon } from "components/v3/icons";
import { ButtonLink, MonoText, SubTitle, Tag } from "components/v3/shared";
import { transform } from "lodash";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  background-color: #1f1f1f;
  color: #ffffff;
  display: grid;
  justify-content: center;
  justify-items: center;
  gap: 0px;
  padding: 90px 0;
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 1140px;

  & > :last-child {
    border-right: none;
  }
`;

const Column = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 20px;
  border-right: 1px solid #ffffff;
  padding: 0 40px;
  line-height: 1.5;
`;

const ColumnTitle = styled.div`
  font-family: Alexandria;
  font-size: 19px;
  font-weight: 700;
  line-height: 19.95px;
  text-align: left;
`;

const TopMessageContainer = styled.div`
  width: 800px;
  background-color: #1473df;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  padding: 80px 162px;
  padding-bottom: 100px;
  display: grid;
  justify-items: center;
`;

const TOP_LEFT = {
  position: "absolute",
  top: -0.5,
  left: 0,
  fill: "#ffffff",
  transform: "rotate(180deg)",
};

const BOTTOM_RIGHT = {
  position: "absolute",
  bottom: 0,
  right: 0,
  fill: "#1f1f1f",
};

const TOP_RIGHT = {
  position: "absolute",
  top: -25.5,
  right: -26 * 2,
  fill: "#1473df",
  transform: "scaleX(-1)",
};

const BOTTOM_LEFT = {
  position: "absolute",
  bottom: -25.5,
  left: -26 * 2,
  fill: "#1473df",
  transform: "scaleY(-1)",
};

const OffsetMessageAndColumns = ({ topMessage = {}, bottomMessage = {}, tagText = "", messages = [] }) => {
  return (
    <Container>
      <TopMessageContainer>
        <DownShapeIcon style={TOP_LEFT} />
        <DownShapeIcon style={BOTTOM_RIGHT} />
        <LeftExtandedShapeIcon style={TOP_RIGHT} />
        <LeftExtandedShapeIcon style={BOTTOM_LEFT} />

        <Tag>{tagText}</Tag>
        <Gap height="40px" />
        <SubTitle style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: topMessage?.title }} />
        <Gap />
        <div
          style={{ width: 380, lineHeight: 1.2, textAlign: "center", color: "white" }}
          dangerouslySetInnerHTML={{ __html: topMessage?.description }}
        />
      </TopMessageContainer>
      <Gap height="220px" />
      <DownShapeIcon style={{ fill: "#1473DF" }} />
      <Gap height="120px" />
      <SubTitle style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: bottomMessage?.title }} />
      <Gap />
      <MonoText
        style={{ width: 740, textAlign: "center", color: "white" }}
        dangerouslySetInnerHTML={{ __html: bottomMessage?.description }}
      />
      <Gap height="100px" />
      <Columns>
        {messages?.map((message, i) => (
          <Column key={i}>
            {message.icon}
            <ColumnTitle dangerouslySetInnerHTML={{ __html: message?.title }} />
            <div>{message.text}</div>
          </Column>
        ))}
      </Columns>
    </Container>
  );
};

export default OffsetMessageAndColumns;
