import BannerV3 from "components/BannerV3";
import Benchmarks from "components/Benchmarks";
import FooterV3 from "components/FooterV3";
import HeroV3 from "components/HeroV3";
import MessagesV3 from "components/MessagesV3";
import MessagesWithGraphicV3 from "components/MessagesWithGraphicV3";
import NavFloating from "components/NavFloating";
import OffsetMessageAndColumns from "components/OffsetMessageAndColumns";
import OurFoundersV3 from "components/OurFoundersV3";
import SelectorSectionExpandingV3 from "components/SelectorSectionExpandingV3";
import SelectorSectionV3 from "components/SelectorSectionV3";
import TitleWithTag from "components/TitleWithTag";
import {
  ChatBotsGraphic,
  CoPilotGraphic,
  EmailGraphic,
  GenerativeDataScienceGraphic,
  VideoOnGrid,
  WorkflowGraphic,
} from "components/v3/graphics";
import {
  LShapeIcon,
  ManRaisingHandIcon,
  PeopleTalkingIcon,
  SpaceShipIcon,
  StarsIcon,
  TShapeIcon,
  TickInCircleIcon,
  TwoDiagonalArrowsIcon,
  ZShapeIcon,
} from "components/v3/icons";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { uuidv4 } from "utils/common";

export const CENTER_COLUMN_WIDTH = 1200;
export const COLOR1 = "#38D0DA";
export const COLOR2 = "#497FF3";
export const BORDER_TOP_GREY = "#9b9b9b";

export const GRADIENT_TEXT = `
background: linear-gradient(88.57deg, ${COLOR2} 17.21%, ${COLOR1} 62.45%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
`;

export const GRADIENT_TEXT_FLIPPED = `
background: linear-gradient(88.57deg, ${COLOR1} 17.21%, ${COLOR2} 62.45%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
`;

export const SIDE_MARGIN = 150;

const SECTIONS = [
  {
    render: () => <NavFloating key={uuidv4()} />,
  },
  {
    render: props => <TitleWithTag key={uuidv4()} {...props} />,
    props: {
      tagText: "ABOUT US",
      title: "We innovate to make<br/>a <span class='blue-highlight'>brighter GenAI</span> future",
      subTitle: "We build the future, one GenAI solution at a time.",
      buttonText: "Join Us",
    },
  },
  {
    render: props => <OffsetMessageAndColumns key={uuidv4()} {...props} />,
    props: {
      tagText: "VISION",
      topMessage: {
        title: "Our Goal",
        description:
          "GenAI should be accessible and usable for all organizations. Powerful technologies must be in the hands of the many.",
      },
      bottomMessage: {
        title: "Our values",
        description: "We envision a future where machines, organisations, and humans collaborate seamlessly.",
      },
      messages: [
        {
          icon: <PeopleTalkingIcon />,
          title: "Support each other",
          text: "We work as a team to support each other and our customers.",
        },
        {
          icon: <ManRaisingHandIcon />,
          title: "Take ownership",
          text: "The best results come from taking ownership of your work and your team's work.",
        },
        {
          icon: <StarsIcon />,
          title: "Build cool stuff",
          text: "We're here to build the coolest GenAI solutions on the planet.",
        },
      ],
    },
  },
  {
    render: props => <SelectorSectionExpandingV3 key={uuidv4()} {...props} />,
    props: {
      title: "Our teams",
      description:
        "We are researchers, engineers, and operational leaders, with experience spanning a variety of disciplines, all working together to build reliable GenAI systems.",
      options: [
        {
          title: "Research",
          content:
            "Our research team, composed of leading experts in machine learning, computer vision, and natural language processing, is dedicated to advancing the forefront of GenAI technology.",
        },
        {
          title: "Business",
          content:
            "Our business team drives strategic growth and fosters partnerships, ensuring we create value and deliver innovative solutions to our clients.",
        },
        {
          title: "Product",
          content:
            "Our product team is focused on designing intuitive, high-impact solutions that meet the evolving needs of our users and shape the future of AI technology.",
        },
        {
          title: "Engineering",
          content:
            "Our engineering team builds robust, scalable systems that power the next generation of AI, ensuring reliability, performance, and cutting-edge capabilities.",
        },
      ],
    },
  },
  {
    render: props => <OurFoundersV3 key={uuidv4()} {...props} />,
    props: {
      title: "Our Founders",
      // description: "Short text about founders.",
      messages: [
        {
          icon: <img src="/images/yichuan.png" />,
          title: "Dr. Yichuan Zhang",
          subTitle: "CEO & Co-founder",
          text: "Ex-Google,<br />University of Cambridge",
          linkedInUrl: "https://uk.linkedin.com/in/dr-yichuan-zhang-46074955",
        },
        {
          icon: <img src="/images/jinli.png" />,
          title: "Dr. Jinli Hu",
          subTitle: "CEO & Co-founder",
          text: "Ex-Microsoft,<br/>University of Edinburgh",
          linkedInUrl: "https://uk.linkedin.com/in/arkjinli",
        },
        {
          icon: <img src="/images/ollie.png" />,
          title: "Ollie Gough",
          subTitle: "COO",
          text: "Ex-Sylvera,<br/>Imperial College London",
          linkedInUrl: "https://uk.linkedin.com/in/ollie-gough-75950990",
        },
        {
          icon: (
            <div style={{ width: 236, height: 236, overflow: "hidden" }}>
              <img src="/images/ivan.jpeg" style={{ width: 280, height: 280 }} />
            </div>
          ),
          title: "Ivan Mihov",
          subTitle: "Head of Business Development",
          text: "Ex-Bloomberg,<br/>University of London",
          linkedInUrl: "https://www.linkedin.com/in/ivanimihov/",
        },
      ],
    },
  },
  {
    render: props => <FooterV3 />,
  },
];

const DesktopContainer = styled.div`
  font-family: Alexandria;
  font-size: 16px;
  font-weight: 300;

  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Container = styled.div``;

const AboutUsPageV3 = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("token") === "bzabc") {
      localStorage.setItem("token", searchParams.get("token"));
    }
  }, [searchParams.get("token")]);

  const token = searchParams?.get("token") || localStorage.getItem("token");

  // if (token !== "bzabc") {
  //   return null;
  // }

  return (
    <Container>
      <DesktopContainer>{SECTIONS?.map(({ render, props }) => render(props))}</DesktopContainer>
    </Container>
  );
};

export default AboutUsPageV3;
