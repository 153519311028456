import FooterV3 from "components/FooterV3";
import NavFloating from "components/NavFloating";
import ProductSections from "components/ProductSections";

import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { uuidv4 } from "utils/common";

export const BGS = [
  "/images/backgrounds/0.jpg",
  "/images/backgrounds/1.jpg",
  "/images/backgrounds/2.jpg",
  "/images/backgrounds/3.jpg",
  "/images/backgrounds/4.jpg",
  "/images/backgrounds/5.jpg",
  "/images/backgrounds/6.jpg",
  "/images/backgrounds/7.jpg",
];

const SECTIONS = [
  {
    render: () => <NavFloating key={uuidv4()} />,
  },
  {
    render: props => <ProductSections key={uuidv4()} {...props} />,
    props: {
      title: "Boltzflow Platform<br>Customised GenAI Assistant",
      description: "Get answers. Analyse results. Automate workflows.",
      sections: [
        {
          title: "<span class='blue-highlight'>Chat</span> with proprietary data and public internet",
          imageSrc: "/images/product/chat.png",
        },
        {
          title: "Engage in a familiar, user-friendly <span class='blue-highlight'>Word</span> platform",
          imageSrc: "/images/product/word.png",
        },
        {
          title: "Create custom <span class='blue-highlight'>Apps</span> from scratch, no coding required",
          imageSrc: "/images/product/analytics.png",
        },
      ],
      smallSections: [
        {
          title: "Send emails effortlessly",
          imageSrc: "/images/product/email.png",
          description: "Easily integrate with any email provider.",
          backgroundImgSrc: BGS[0],
        },
        {
          title: "Seamlessly Ingest Documents",
          imageSrc: "/images/product/search-bar.png",
          description: "Effortlessly upload and process files.",
          backgroundImgSrc: BGS[1],
        },
        {
          title: "Streamline Workflow Automation",
          imageSrc: "/images/product/workflow.png",
          description: "Automate tasks and improve efficiency",
          backgroundImgSrc: BGS[2],
        },
        {
          title: "Customisable Analytics Dashboards",
          imageSrc: "/images/product/chart-edit.png",
          description: "Gain actionable insights and make data-driven decisions.",
          backgroundImgSrc: BGS[3],
        },
      ],
    },
  },
  {
    render: props => <FooterV3 />,
  },
];

const DesktopContainer = styled.div`
  font-family: Alexandria;
  font-size: 16px;
  font-weight: 300;

  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Container = styled.div``;

const ProductPageV3 = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("token") === "bzabc") {
      localStorage.setItem("token", searchParams.get("token"));
    }
  }, [searchParams.get("token")]);

  const token = searchParams?.get("token") || localStorage.getItem("token");

  // if (token !== "bzabc") {
  //   return null;
  // }

  return (
    <Container>
      <DesktopContainer>{SECTIONS?.map(({ render, props }) => render(props))}</DesktopContainer>
    </Container>
  );
};

export default ProductPageV3;
