import { Gap } from "components/Layout";
import { MonoText, SubTitle, Title } from "components/v3/shared";
import styled from "styled-components";

const Container = styled.div`
  background-color: #1f1f1f;
  color: white;
  display: grid;
  /* justify-content: center;
  justify-items: center; */
  gap: 0px;
  padding: 200px 120px;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    padding: 90px 20px;
  }
`;

const DarkBannerV3 = ({ title = "", description = "" }) => {
  return (
    <Container>
      <Title style={{ fontSize: 52 }} dangerouslySetInnerHTML={{ __html: title }} />
      <Gap height="20px" />
      <MonoText>{description}</MonoText>
    </Container>
  );
};

export default DarkBannerV3;
