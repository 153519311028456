import styled from "styled-components";

export const Title = styled.div`
  font-size: 57px;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  font-family: "Alexandria", sans-serif;

  .blue-highlight {
    font: inherit;
    color: #1473df;
    line-height: inherit;
  }
`;

export const SubTitle = styled.div`
  font-size: 35px;
  line-height: 1.2;
  font-weight: 600;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  font-family: "Alexandria", sans-serif;

  .blue-highlight {
    font: inherit;
    color: #1473df;
    line-height: inherit;
  }
`;

export const MonoText = styled.div`
  font-family: "Martian Mono", monospace;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.5;
  color: #666666;

  .black-highlight {
    font: inherit;
    color: white;
    line-height: inherit;
    background-color: #1f1f1f;
    outline: 2px solid #1f1f1f;
    padding: 2px;
    margin-left: -4px;
  }
`;

export const Tag = styled.a`
  display: block;
  font-family: Martian Mono;
  border: 1px solid ${props => props.color || "#ffffff"};
  border-radius: 6px;
  padding: 4px 20px;
  width: max-content;
  color: ${props => props.color || "#ffffff"};
  font-weight: 300;
`;

const ButtonLinkContainer = styled.a`
  padding: 15px 22px 15px 22px;
  gap: 10px;
  border-radius: 8px;
  opacity: 0px;
  background-color: #f6f6f6;
  color: #1f1f1f !important;
  width: max-content;

  font-family: Martian Mono;
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  text-decoration: none;
  color: #1f1f1f;

  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  ${props => props?.disabled && "opacity: 0.5; pointer-events: none;"}

  :hover {
    background-color: #d8d8d8;
  }
`;

const BlueSquare = styled.div`
  width: 8px;
  height: 8px;
  background-color: #1473df;
`;

export const ButtonLink = ({ target = "", onClick = () => {}, disabled = false, style = {}, href, children = [] }) => (
  <ButtonLinkContainer target={target} onClick={onClick} disabled={disabled} href={href} style={style}>
    <BlueSquare />
    {children}
  </ButtonLinkContainer>
);
