import ContactUsModal from "components/ContactUsModal";
import { Gap } from "components/Layout";
import { ButtonLink, MonoText, SubTitle } from "components/v3/shared";
import styled from "styled-components";

const Container = styled.div`
  background-color: #ebebeb;
  display: grid;
  justify-content: center;
  justify-items: center;
  gap: 0px;
  padding: 132px 0;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    display: none;
  }
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 1140px;

  & > :last-child {
    border-right: none;
  }
`;

const Column = styled.div`
  display: grid;
  gap: 20px;
  border-right: 1px solid #1f1f1f;
  padding: 0 40px;
  line-height: 1.5;
`;

const ColumnTitle = styled.div`
  font-family: Alexandria;
  font-size: 19px;
  font-weight: 700;
  line-height: 19.95px;
  text-align: left;
`;

const MessagesWithGraphicV3 = ({ title = "", description = "", messages = [], graphic = null }) => {
  return (
    <Container>
      <SubTitle style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: title }} />
      <Gap />
      <MonoText style={{ width: 740, textAlign: "center" }} dangerouslySetInnerHTML={{ __html: description }} />
      <Gap height="80px" />
      {graphic}
      <Gap height="80px" />
      <Columns>
        {messages?.map((message, i) => (
          <Column key={i}>
            {message.icon}
            <ColumnTitle dangerouslySetInnerHTML={{ __html: message?.title }} />
            <div>{message.text}</div>
          </Column>
        ))}
      </Columns>
      <Gap height="80px" />
      {/* <ButtonLink href="/">Learn more</ButtonLink> */}
      <ContactUsModal triggerComponent={<ButtonLink>Learn more</ButtonLink>} />
    </Container>
  );
};

export default MessagesWithGraphicV3;
