import styled from "styled-components";
import { LinkedinIcon, LogoV3, XIcon } from "components/ui/Icons";

const BlackBg = styled.div`
  background-color: #151617;
`;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  color: white;
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100vw;
  /* padding-bottom: 1000px; */

  @media (min-width: 1240px) {
    padding: 0;
    padding-top: 0px;
    width: 1200px;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;
  padding-bottom: 80px;
  gap: 15px;

  @media (min-width: 768px) {
    gap: 0;
    flex-wrap: nowrap;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;

  svg {
    fill: white;
    stroke: white;
  }

  @media (min-width: 768px) {
    justify-content: end;
  }
`;

const SeparatorContainer = styled.div`
  grid-column: span 2;
  display: grid;
  grid-template-columns: 5px 1fr 5px;
  gap: 8px;
  align-items: center;
  padding-bottom: 30px;
`;

const Box = styled.div`
  width: 5px;
  height: 5px;
  background-color: white;
  /* background-color: ${props => props.theme.color.primary}; */
`;

const Line = styled.div`
  height: 1px;
  background-color: ${props => props.theme.color.closer0};
`;

const BottomSeparator = () => (
  <SeparatorContainer>
    <Box />
    <Line />
    <Box />
  </SeparatorContainer>
);

const BottomLinks = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  padding-bottom: 30px;
  justify-content: start;

  @media (min-width: 1240px) {
    grid-auto-flow: column;
  }
`;

const GreyText = styled.div`
  font-size: 14px;
  color: #b4b4b4;
`;

const GreyLink = styled.a`
  font-size: 14px;

  text-decoration: none;
  color: #b4b4b4;
  cursor: pointer;

  :hover {
    color: white;
  }
`;

const IconA = styled.a`
  :hover {
    opacity: 0.8;
  }
`;

const LogoImg = styled.img`
  height: 42px;
`;

/*
{ width: "max-content", gridColumn: "span 2", paddingBottom: "200px", paddingTop: "80px" }
*/
const StyledA = styled.a`
  width: max-content;
  grid-column: span 2;
  padding-bottom: 200px;
  padding-top: 80px;

  @media (max-width: 1240px) {
    padding-bottom: 20px;
    padding-top: 0px;
  }
`;

const LinkHome = styled.a`
  width: max-content;
  grid-column: span 2;

  text-decoration: none;
  display: flex;
  align-items: center;
  width: max-content;

  font-family: "Martian Mono", monospace;
  font-family: Martian Mono;
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  text-align: center;
  text-decoration: none;

  display: flex;
  align-items: center;
  gap: 4px;

  color: white;

  padding-bottom: 200px;
  padding-top: 80px;

  @media (max-width: 1240px) {
    padding-bottom: 20px;
    padding-top: 0px;
  }
`;

const FooterSectionV2 = () => {
  return (
    <BlackBg>
      <Container>
        {/* <StyledA href="/">
          <LogoImg src="/images/boltzbit_logo_h_white.png" />
        </StyledA> */}
        <LinkHome href="/">
          <LogoV3 /> BOLTZBIT
        </LinkHome>
        <BottomLinks>
          <GreyText>© 2024 Copyright All Right Reserved.</GreyText>
          <GreyText>Boltzbit Ltd</GreyText>

          <GreyLink target="_blank" href="/terms">
            Terms & Conditions
          </GreyLink>
          <GreyLink target="_blank" href="/privacy-policy">
            Privacy Policy
          </GreyLink>
          <GreyLink href="/flow/privacy-policy">Product Data Policy</GreyLink>
          <GreyLink href="/flow/terms">Product Terms</GreyLink>
        </BottomLinks>
        <LinksContainer>
          <SocialIcons>
            <IconA href="https://twitter.com/boltzbit?lang=en" target="_blank" rel="noreferrer">
              <XIcon />
            </IconA>
            <IconA href="https://uk.linkedin.com/company/boltzbit" target="_blank" rel="noreferrer">
              <LinkedinIcon height="18px" />
            </IconA>
          </SocialIcons>
        </LinksContainer>
      </Container>
    </BlackBg>
  );
};

export default FooterSectionV2;
