import { postContactUsDetails } from "api/backend/marketingServiceEndpoints";
import { LogoV3 } from "components/ui/Icons";
import { ButtonLink, MonoText, SubTitle, Tag } from "components/v3/shared";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  padding: 120px 150px;
  background-color: #ffffff;
  justify-items: center;
`;

const Banner = styled.div`
  background-color: #1f1f1f;
  padding: 40px;
  color: #ffffff;

  display: grid;
  grid-template-columns: 1fr auto;
  max-width: 1140px;
  width: 100%;
  svg {
    height: 100%;
    width: 100%;
  }
`;

const StyledInput = styled.input`
  padding: 10px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  width: 100%;
  background-color: transparent;
  color: #ffffff;
  max-width: 365px;
  font-family: Martian Mono;
  outline: none;
  :focus {
    border: 1px solid #1473df;
  }
`;

const LeftColumn = styled.div`
  display: grid;
  gap: 20px;
`;

const JoinNewsletterBanner = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const doSubmitEmail = async () => {
    setError("");
    setSuccessMsg("");
    setIsSubmitDisabled(true);

    const { error } = await postContactUsDetails({
      email,
      product: "newsletter",
    });

    setError(error);
    setIsSubmitDisabled(false);
    if (!error) {
      setSuccessMsg("You are subscribed. Thank you.");
    }
  };

  return (
    <Container>
      <Banner>
        <LeftColumn>
          <Tag>STAY UP TO DATE</Tag>
          <SubTitle>Join our newsletter</SubTitle>
          <div style={{ display: "flex", gap: 10 }}>
            <StyledInput value={email} onChange={e => setEmail(e.target.value)} placeholder="Enter your email..." />
            <ButtonLink onClick={doSubmitEmail} disabled={isSubmitDisabled || !email}>
              Submit
            </ButtonLink>
          </div>
          <MonoText style={{ color: "#ffffff" }}>*You can unsubscribe anytime</MonoText>
          {error && <MonoText style={{ color: "#ff000094" }}>{error?.message}</MonoText>}
          {successMsg && <MonoText style={{ color: "#00ff0094" }}>{successMsg}</MonoText>}
        </LeftColumn>
        <LogoV3 />
      </Banner>
    </Container>
  );
};

export default JoinNewsletterBanner;
