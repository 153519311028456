import ContactUsModal from "components/ContactUsModal";
import { Gap } from "components/Layout";
import { TickInCircleIcon } from "components/v3/icons";
import { ButtonLink, MonoText, SubTitle, Tag, Title } from "components/v3/shared";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  background-color: #ebebeb;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: start;
  padding: 132px 120px;
`;

const Models = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
`;

const ModelCard = styled.div`
  display: grid;
  gap: 20px;
  padding: 40px;
  line-height: 1.5;
  background-color: #ffffff;
  width: 500px;
`;

const IconAndText = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  svg {
    height: 16px;
    width: 16px;
  }
`;

const SmallSection = styled.div`
  position: relative;
  margin-bottom: 120px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  align-items: center;

  background-color: #1f1f1f;
  border-radius: 8px;

  height: 550px;
  width: 1090px;

  * {
    color: #ffffff;
  }
`;

const ModelFeatures = styled.div`
  display: grid;
  gap: 15px;
`;

const SmallImgContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.src});
  background-size: cover;

  display: grid;
  align-items: center;
  justify-items: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const BackgroundPane = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.2;
`;

const SmallImg = styled.img`
  width: 90%;
  object-fit: cover;
  object-position: top left;
  border-radius: 8px;
  position: relative;
`;

const NavItems = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1090px;
`;

const CodeGenHero = ({ title = "", description = "", modelsTitle = "", models = [], smallSections = [] }) => {
  const [selectedNavTitle, setSelectedNavTitle] = useState(smallSections[0].navTitle);

  return (
    <Container>
      <Tag color="#1f1f1f">PRODUCT</Tag>
      <Gap height="40px" />
      <Title style={{ textAlign: "center", lineHeight: 1.2 }} dangerouslySetInnerHTML={{ __html: title }} />
      <Gap />
      <MonoText style={{ width: 740, textAlign: "center" }} dangerouslySetInnerHTML={{ __html: description }} />
      <Gap height="40px" />
      <ContactUsModal triggerComponent={<ButtonLink>Coming soon</ButtonLink>} />

      <Gap height="120px" />
      <SubTitle dangerouslySetInnerHTML={{ __html: modelsTitle }} />
      <Gap height="0px" />
      {/* <NavItems>
        {smallSections?.map(section => {
          const { navTitle } = section;
          const isSelected = selectedNavTitle === navTitle;

          return (
            <Tag
              style={{ cursor: "pointer" }}
              color={isSelected ? "#1473DF" : "#1F1F1F"}
              onClick={() => setSelectedNavTitle(navTitle)}
            >
              {navTitle}
            </Tag>
          );
        })}
      </NavItems> */}
      <Gap height="0px" />
      {smallSections?.map(section => {
        const { title, imageSrc, description, navTitle, backgroundImgSrc } = section;
        const isSelected = selectedNavTitle === navTitle;

        return (
          <SmallSection style={{ display: isSelected ? "grid" : "none" }}>
            <div style={{ padding: "0 60px" }}>
              <SubTitle style={{ fontSize: 28 }} dangerouslySetInnerHTML={{ __html: title }} />
              <Gap height="30px" />
              <div
                style={{ lineHeight: 1.5 }}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </div>
            <SmallImgContainer src={backgroundImgSrc}>
              <BackgroundPane />
              <SmallImg src={imageSrc} />
            </SmallImgContainer>
          </SmallSection>
        );
      })}
    </Container>
  );
};

export default CodeGenHero;
