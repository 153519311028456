import styled from "styled-components";
import { PRIVACY_HTML_STR } from "components/Privacy";
import { LogoV3 } from "components/ui/Icons";

const Container = styled.div`
  padding: 100px;
  max-width: 1200px;
  line-height: 1.8;
`;

const TermsContent = styled.div`
  font-family: "Montserrat", sans-serif;

  td {
    border: 1px solid #5a5a5a;
    padding: 4px 8px;
  }
  .c3 {
    font-weight: 600;
  }
  .c33 {
    text-decoration: underline;
    font-weight: 600;
  }
  .c7 {
    margin-bottom: 20px;
  }
`;

const LogoImg = styled.img`
  height: 24px;
`;

const TopBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 80px;
  background-color: black;
  display: grid;
  align-items: center;
`;

const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
`;

const SmallText = styled.div`
  margin-top: 0px;
`;

const LinkHome = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  width: max-content;

  font-family: "Martian Mono", monospace;
  font-family: Martian Mono;
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  text-align: center;
  text-decoration: none;

  display: flex;
  align-items: center;
  gap: 4px;

  color: white;
`;

const FlowPrivacyPage = () => {
  // const onPaste = e => {
  //   // print html content
  //   console.log(e.clipboardData.getData("text/html"));
  // };

  // useEffect(() => {
  //   document.addEventListener("paste", onPaste);
  //   return () => {
  //     document.removeEventListener("paste", onPaste);
  //   };
  // }, []);

  return (
    <Container>
      <TopBar>
        <LinkHome href="/">
          <LogoV3 /> BOLTZBIT
        </LinkHome>
      </TopBar>
      <Title>Privacy Policy - Generative AI Solution Platform</Title>
      <SmallText>Updated: 12 December 2024</SmallText>
      <TermsContent dangerouslySetInnerHTML={{ __html: PRIVACY_HTML_STR }} />
    </Container>
  );
};

export default FlowPrivacyPage;
