import { Gap } from "components/Layout";
import { LeftExtandedShapeIcon } from "components/v3/icons";
import { MonoText, SubTitle } from "components/v3/shared";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  position: relative;
  justify-content: center;
  justify-items: center;
  gap: 0px;
  padding: 120px 0;

  align-content: start;
  position: relative;
  background-color: #1f1f1f;
  color: #ffffff;
`;

const Column = styled.div`
  display: grid;
  gap: 8px;
  border-right: 1px solid #1f1f1f;
  line-height: 1.5;
  align-content: center;
`;

const ColumnTitle = styled.div`
  font-family: Alexandria;
  font-size: 19px;
  font-weight: 700;
  line-height: 19.95px;
  text-align: left;
`;

const ColumnDescription = styled.div``;

const BenchmarkText = styled.div`
  font-family: Martian Mono;
  font-size: 97px;
  font-weight: 700;
  line-height: 116.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1473df;
`;

const BenchmarkTextInverse = styled(BenchmarkText)``;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.8fr;
  max-width: 1000px;
  gap: 120px;
`;

const RectContainer = styled.div`
  padding: 40px;
  color: white;
  background-color: #1473df;
  display: grid;
  gap: 20px;
  line-height: 1.2;
`;

const RectContainerInverse = styled(RectContainer)`
  background-color: #ebebeb;
  color: #1f1f1f;
`;

const RectTitle = styled.div`
  font-family: Martian Mono;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const RectSubTitle = styled.div`
  font-family: Martian Mono;
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const LEFT_STYLE = {
  position: "absolute",
  top: -25.5,
  left: 123,
};

const BenchmarksExtended = ({ title = "", description = "", benchmarks = [] }) => {
  return (
    <Container>
      <LeftExtandedShapeIcon style={LEFT_STYLE} />

      <SubTitle style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: title }} />
      <Gap />
      <MonoText
        style={{ width: 740, textAlign: "center", color: "white" }}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <Gap height="100px" />

      <TwoColumns>
        <Column>
          <BenchmarkText>
            {benchmarks?.[0]?.value}
            {benchmarks?.[0]?.suffix}
          </BenchmarkText>
          <Gap />
          <ColumnTitle dangerouslySetInnerHTML={{ __html: benchmarks?.[0]?.title }} />
          <ColumnDescription>{benchmarks?.[0]?.description}</ColumnDescription>
        </Column>
        <div>
          <RectContainer>
            <RectTitle>{benchmarks?.[0]?.messages?.[0]?.title}</RectTitle>
            <div>{benchmarks?.[0]?.messages?.[0]?.description}</div>
          </RectContainer>
          <RectContainerInverse>
            <RectTitle>{benchmarks?.[0]?.messages?.[1]?.title}</RectTitle>
            <div>{benchmarks?.[0]?.messages?.[1]?.description}</div>
          </RectContainerInverse>
        </div>
      </TwoColumns>
      <Gap height="120px" />

      <TwoColumns style={{ gridTemplateColumns: "2fr 1fr" }}>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <RectSubTitle style={{ gridColumn: "span 2", paddingBottom: 20 }}>
            The main source of revenue is...
          </RectSubTitle>
          <RectContainer>
            <RectTitle>{benchmarks?.[1]?.messages?.[0]?.title}</RectTitle>
            <RectSubTitle>{benchmarks?.[1]?.messages?.[0]?.subTitle}</RectSubTitle>
            <Gap height="0px" />
            <div>{benchmarks?.[1]?.messages?.[0]?.description}</div>
          </RectContainer>
          <RectContainerInverse>
            <RectTitle>{benchmarks?.[1]?.messages?.[1]?.title}</RectTitle>
            <RectSubTitle>{benchmarks?.[1]?.messages?.[1]?.subTitle}</RectSubTitle>
            <Gap height="0px" />
            <div>{benchmarks?.[1]?.messages?.[1]?.description}</div>
          </RectContainerInverse>
        </div>
        <Column>
          <BenchmarkTextInverse>
            {benchmarks?.[1]?.value}
            {benchmarks?.[1]?.suffix}
          </BenchmarkTextInverse>
          <Gap />
          <ColumnTitle dangerouslySetInnerHTML={{ __html: benchmarks?.[1]?.title }} />
          <ColumnDescription>{benchmarks?.[1]?.description}</ColumnDescription>
        </Column>
      </TwoColumns>
    </Container>
  );
};

export default BenchmarksExtended;
