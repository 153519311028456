import ContactUsModal from "components/ContactUsModal";
import { Gap } from "components/Layout";
import { ButtonLink, MonoText, SubTitle, Tag, Title } from "components/v3/shared";
import styled from "styled-components";

const Container = styled.div`
  background-color: #ebebeb;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: start;
  padding: 132px 120px;
`;

const Section = styled.div`
  margin-bottom: 120px;
`;

const SmallSection = styled.div`
  position: relative;
  margin-bottom: 120px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  align-items: center;

  background-color: #1f1f1f;
  border-radius: 8px;

  height: 600px;
  width: 1090px;

  * {
    color: #ffffff;
  }
`;

const Img = styled.img`
  height: 600px;
  border-radius: 8px;
`;

const SmallImgContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.src});
  background-position: -60px 0;
  background-size: cover;

  display: grid;
  align-items: center;
  justify-items: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const SmallImg = styled.img`
  width: 90%;
  object-fit: cover;
  object-position: top left;
  border-radius: 20px;
`;

const ProductSections = ({ title = "", description = "", sections = [], smallSections = [] }) => {
  return (
    <Container>
      <Tag color="#1f1f1f">PRODUCT</Tag>
      <Gap height="40px" />
      <Title style={{ textAlign: "center", lineHeight: 1.2 }} dangerouslySetInnerHTML={{ __html: title }} />
      <Gap />
      <MonoText style={{ width: 740, textAlign: "center" }} dangerouslySetInnerHTML={{ __html: description }} />
      <Gap height="40px" />
      <ContactUsModal triggerComponent={<ButtonLink>Request demo</ButtonLink>} />
      <Gap height="120px" />
      {sections?.map(section => {
        const { title, imageSrc } = section;

        return (
          <Section>
            <SubTitle style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: title }} />
            <Gap height="30px" />
            <Img src={imageSrc} />
          </Section>
        );
      })}
      {smallSections?.map(section => {
        const { title, imageSrc, description, backgroundImgSrc } = section;

        return (
          <SmallSection>
            <div style={{ padding: "0 60px" }}>
              <SubTitle style={{ fontSize: 28 }} dangerouslySetInnerHTML={{ __html: title }} />
              <Gap height="30px" />
              <div
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </div>
            <SmallImgContainer src={backgroundImgSrc}>
              <SmallImg src={imageSrc} />
            </SmallImgContainer>
          </SmallSection>
        );
      })}
    </Container>
  );
};

export default ProductSections;
