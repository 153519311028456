import FooterSectionV2 from "components/FooterSectionV2";
import TopBarV2 from "components/TopBarV2";
import { TermsTopBar } from "pages/flow/terms";
import styled from "styled-components";

const Container = styled.div`
  padding-top: 120px;
  background-color: #151617;
  color: white;
  line-height: 1.5;
  display: grid;
  justify-content: center;

  * {
    font-size: 14px;
  }

  strong {
    font-weight: 600;
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const TwoColumns = styled.div`
  display: grid;
  padding: 20px;
  gap: 40px;
  a {
    color: lightblue;
  }

  @media (min-width: 1200px) {
    padding: 0;
    width: 1200px;
    grid-template-columns: 1fr 3fr;
  }
`;

const PrivacyPolicyPage = () => {
  return (
    <Container>
      {/* <TopBarV2 /> */}
      <TermsTopBar />
      <TwoColumns>
        <Title>PRIVACY POLICY</Title>
        <div>
          <p>
            We are Boltzbit Limited (CRN: 12398587).
            <br />
            Our registered office is at: Office 202 221 Pentonville Road, London, England, N1 9UZ
            <br />
            If you have any questions about this privacy notice, including any requests to exercise your legal rights,
            please contact us at privacy@boltzbit.com.
          </p>
          <br />
          <p>
            HOW DO WE USE YOUR DATA?
            <br />
            1. When you sign up to be the first to know about our launch and receive our news updates. We will handle
            your personal information (such as your name and email address) to provide you with our news updates in line
            with any preferences you have told us about.
            <br />
            When we send you our news updates because you have opted-in to receive them, we rely on your consent to
            contact you.
            <br />
            You can unsubscribe from our updates at any time by clicking the unsubscribe link at the bottom of any of
            our emails or by emailing privacy@boltzbit.com.
            <br />
            2. When you contact us. When you contact us either by phone, email, or via social media, we will usually
            collect your name and contact details, because it’s in our legitimate interest to make sure we can properly
            respond to your query.
            <br />
            3. On social media. When you connect with us on social media including on LinkedIn we will process your
            handle, name and email address under our legitimate interest to respond to your comments and queries
            promptly.
            <br />
            4. When you attend one of our events. When you attend one of our events, we will usually collect your name,
            address, email address and phone number. We collect this information because it’s in our legitimate
            interests to know who’s attending our events.
            <br />
            5. If our business is sold. We process your personal information for this purpose because we have a
            legitimate interest to ensure our business can be continued by the buyer. If you object to our use of your
            personal information in this way, the buyer of our business may not be able to provide services to you.
          </p>

          <p>
            <strong>6. When you sign up to compete in one of our competitions including BoltzGo. </strong>We will
            collect your name, email address and organisation under our legitimate interest to know who is competing and
            to ensure our competitions run as you would reasonably expect them to, including allowing us to award
            prizes.
          </p>

          <p>
            <br />
            WHERE IS MY DATA STORED?
            <br />
            We store your data securely in private/cloud-based databases fully managed by us to provide best protection.
          </p>

          <p>
            Whenever we transfer your personal information outside of the UK and the EU, we ensure it receives
            additional protection as required by law. To keep this privacy policy as short and easy to understand as
            possible, we haven’t set out the specific circumstances when each of these protection measures are used. You
            can contact us at <a href="mailto:privacy@boltzbit.com">privacy@boltzbit.com</a> for more detail on this.
          </p>

          <p>​</p>

          <p>
            HOW LONG DO WE KEEP YOUR DATA FOR?
            <br />
            We will only retain your personal information for as long as we need it. Your data is usually deleted after
            30 days, unless we are required to keep it for longer to comply with our legal, accounting or regulatory
            requirements.
          </p>

          <p>
            In some circumstances we may carefully anonymise your personal data so that it can no longer be associated
            with you, and we may use this anonymised information indefinitely without notifying you. We use this
            anonymised information to analyse our programmes and support other similar programmes around the world.
          </p>

          <p>WHAT ARE MY RIGHTS UNDER DATA PROTECTION LAWS?</p>

          <p>
            You have various other rights under applicable data protection laws, including the right to:
            <br />
            • access your personal data (also known as a “subject access request”);
            <br />
            • correct incomplete or inaccurate data we hold about you;
            <br />
            • ask us to erase the personal data we hold about you;
            <br />
            • ask us to restrict our handling of your personal data;
            <br />
            • ask us to transfer your personal data to a third party;
            <br />
            • object to how we are using your personal data; and
            <br />• withdraw your consent to us handling your personal data.
          </p>

          <p>
            You also have the right to lodge a complaint with us or the Information Commissioner's Office, the
            supervisory authority for data protection issues in England and Wales. If you are based outside of England
            and Wales, you can find your relevant supervisory authority here.
          </p>

          <p>
            Please keep in mind that privacy law is complicated, and these rights will not always be available to you
            all of the time.
          </p>

          <p> </p>

          <p>QUESTIONS, COMMENTS AND MORE DETAIL</p>

          <p>
            Your feedback and suggestions on this notice are welcome.
            <br />
            We’ve worked hard to create a notice that’s easy to read and clear. But if you feel that we have overlooked
            an important perspective or used language which you think we could improve, please let us know by email at
            privacy@boltzbit.com.
          </p>

          <p>
            <br />
            This privacy policy was last updated on July 2024.
          </p>
        </div>
      </TwoColumns>
      <FooterSectionV2 />
    </Container>
  );
};

export default PrivacyPolicyPage;
