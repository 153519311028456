import BlogsList from "components/BlogsList";
import FooterV3 from "components/FooterV3";
import HeroBlogs from "components/HeroBlogs";
import JoinNewsletterBanner from "components/JoinNewsletterBanner";
import NavFloating from "components/NavFloating";
import { useEffect } from "react";

import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { uuidv4 } from "utils/common";

export const CENTER_COLUMN_WIDTH = 1200;
export const COLOR1 = "#38D0DA";
export const COLOR2 = "#497FF3";
export const BORDER_TOP_GREY = "#9b9b9b";

export const GRADIENT_TEXT = `
background: linear-gradient(88.57deg, ${COLOR2} 17.21%, ${COLOR1} 62.45%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
`;

export const GRADIENT_TEXT_FLIPPED = `
background: linear-gradient(88.57deg, ${COLOR1} 17.21%, ${COLOR2} 62.45%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
`;

export const SIDE_MARGIN = 150;

const SECTIONS = [
  {
    render: () => <NavFloating key={uuidv4()} />,
  },
  {
    render: props => <HeroBlogs key={uuidv4()} {...props} />,
    props: {
      title: "<span class='blue-highlight'>Insights</span> from our team",
      subTitle: "Exploring AI innovations, industry trends, and expert perspectives.",
      buttonText: "Talk to us",
      buttonHref: "/",
      videoSrc: "/images/squares.mp4",
      logoSrcs: ["/images/google-cloud-logo.png", "/images/logos/aws-logo.png", "/images/logos/tpicap-logo.png"],
    },
  },
  {
    render: props => <BlogsList key={uuidv4()} />,
  },
  {
    render: props => <JoinNewsletterBanner key={uuidv4()} />,
  },
  {
    render: props => <FooterV3 />,
  },
];

const DesktopContainer = styled.div`
  font-family: Alexandria;
  font-size: 16px;
  font-weight: 300;

  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Container = styled.div``;

const BlogsPageV3 = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("token") === "bzabc") {
      localStorage.setItem("token", searchParams.get("token"));
    }
  }, [searchParams.get("token")]);

  const token = searchParams?.get("token") || localStorage.getItem("token");

  // if (token !== "bzabc") {
  //   return null;
  // }

  return (
    <Container>
      <DesktopContainer>{SECTIONS?.map(({ render, props }) => render(props))}</DesktopContainer>
    </Container>
  );
};

export default BlogsPageV3;
