import { Gap } from "components/Layout";
import { ComparisonPlotGraphic } from "components/v3/graphics";
import { ButtonLink, MonoText, SubTitle, Tag, Title } from "components/v3/shared";
import styled from "styled-components";

const Container = styled.div`
  display: grid;

  align-items: center;
  align-content: center;

  justify-content: center;
  justify-items: center;

  text-align: center;
  background-color: #ffffff;
  padding: 120px 150px;
`;

const Anchor = styled.a`
  font-family: Martian Mono;
  color: #1473df;
`;

const ComparisonPlotSection = () => {
  return (
    <Container>
      <SubTitle style={{ fontSize: 20 }}>FinLLM Bench GPT-4o, Llama 3.1 and Boltzbit LLMs</SubTitle>
      <ComparisonPlotGraphic />
      <div
        style={{
          lineHeight: 1.5,
          textAlign: "left",
          width: 764,
        }}
      >
        <Anchor target="_blank" href="https://huggingface.co/spaces/finosfoundation/Open-Financial-LLM-Leaderboard">
          Open FinLLM Leaderboard
        </Anchor>
        &nbsp;is a financial document analysis benchmark dataset developed by HuggingFace. The results above are
        conducted offline by Boltzbit.
      </div>
    </Container>
  );
};

export default ComparisonPlotSection;
