import BannerV3 from "components/BannerV3";
import Benchmarks from "components/Benchmarks";
import BenchmarksExtended from "components/BenchmarksExtended";
import ComparisonPlotSection from "components/ComparisonPlotSection";
import FooterV3 from "components/FooterV3";
import HeroV3 from "components/HeroV3";
import MessagesV3 from "components/MessagesV3";
import MessagesWithGraphicV3 from "components/MessagesWithGraphicV3";
import NavFloating from "components/NavFloating";
import OffsetMessageAndColumns from "components/OffsetMessageAndColumns";
import OurFoundersV3 from "components/OurFoundersV3";
import PapersSectionV3 from "components/PapersSectionV3";
import SelectorSectionExpandingV3 from "components/SelectorSectionExpandingV3";
import SelectorSectionV3 from "components/SelectorSectionV3";
import TitleWithTag from "components/TitleWithTag";
import {
  ChatBotsGraphic,
  CoPilotGraphic,
  EmailGraphic,
  GenerativeDataScienceGraphic,
  VideoOnGrid,
  WorkflowGraphic,
} from "components/v3/graphics";
import {
  LShapeIcon,
  ManRaisingHandIcon,
  PeopleTalkingIcon,
  SpaceShipIcon,
  StarsIcon,
  TShapeIcon,
  TickInCircleIcon,
  TwoDiagonalArrowsIcon,
  ZShapeIcon,
} from "components/v3/icons";
import { min } from "lodash";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { uuidv4 } from "utils/common";

export const CENTER_COLUMN_WIDTH = 1200;
export const COLOR1 = "#38D0DA";
export const COLOR2 = "#497FF3";
export const BORDER_TOP_GREY = "#9b9b9b";

export const GRADIENT_TEXT = `
background: linear-gradient(88.57deg, ${COLOR2} 17.21%, ${COLOR1} 62.45%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
`;

export const GRADIENT_TEXT_FLIPPED = `
background: linear-gradient(88.57deg, ${COLOR1} 17.21%, ${COLOR2} 62.45%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
`;

export const SIDE_MARGIN = 150;

const SECTIONS = [
  {
    render: () => <NavFloating key={uuidv4()} />,
  },
  {
    render: props => <TitleWithTag key={uuidv4()} {...props} />,
    props: {
      bgColor: "#EBEBEB",
      bottomGapHeight: 0,
      minHeight: "566px",
      tagText: "RESEARCH",
      title: "<span class='blue-highlight'>True innovation</span> makes<br/>the impossible possible",
      subTitle: "Groundbreaking research to make<br/>the next generation LLMs.",
      buttonText: "",
    },
  },
  {
    render: props => <ComparisonPlotSection key={uuidv4()} />,
  },
  {
    render: props => <BenchmarksExtended key={uuidv4()} {...props} />,
    props: {
      title: "Boltzbit LLMs learn to search for<br /> the best sequence of tokens",
      description: "Our models are boosted by tree search<br /> and surpass the best GPTs on the market.",
      benchmarks: [
        {
          value: 21,
          suffix: "x",
          title: "Faster",
          description: "Creating concise answers at unprecedented speed without high-end GPUs.",
          messages: [
            {
              title: "Boltzbit LLM",
              description:
                "Generative AI is a cutting-edge technology that enables machines to produce content, such as images, text, music, and even videos, that mimic human creativity. ",
            },
            {
              title: "Other GPT",
              description:
                "Generative AI is a cutting-edge technology that enables machines to produce content, such as images, text, music, and even videos, that mimic human creativity. ",
            },
          ],
        },
        {
          value: 98,
          suffix: "%",
          title: "Accuracy",
          description: "Generating sequences of tokens provides the best answers for your needs.",
          messages: [
            {
              title: "Boltzbit LLM",
              subTitle: "Top next sequence",
              description:
                "Generative AI is a cutting-edge technology that enables machines to produce content, such as images, text, music, and even videos, that mimic human creativity. ",
            },
            {
              title: "Other GPT",
              subTitle: "Top next sequence",
              description:
                "Generative AI is a cutting-edge technology that enables machines to produce content, such as images, text, music, and even videos, that mimic human creativity. ",
            },
          ],
        },
      ],
    },
  },
  {
    render: props => <PapersSectionV3 key={uuidv4()} {...props} />,
    props: {
      title: "Research papers",
      papers: [
        {
          title: "2012 NeurIPS",
          description: "Continuous Relaxations for Discrete Hamiltonian Monte Carlo",
          url: "https://mlg.eng.cam.ac.uk/pub/pdf/ZhaSutSto12a.pdf",
        },
        {
          title: "2021 ICML",
          description: "A Gradient Based Strategy for Hamiltonian Monte Carlo Hyperparameter Optimization",
          url: "https://proceedings.mlr.press/v139/campbell21a/campbell21a.pdf",
        },
      ],
    },
  },
  {
    render: props => <FooterV3 />,
  },
];

const DesktopContainer = styled.div`
  font-family: Alexandria;
  font-size: 16px;
  font-weight: 300;

  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Container = styled.div``;

const ResearchPageV3 = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("token") === "bzabc") {
      localStorage.setItem("token", searchParams.get("token"));
    }
  }, [searchParams.get("token")]);

  const token = searchParams?.get("token") || localStorage.getItem("token");

  // if (token !== "bzabc") {
  //   return null;
  // }

  return (
    <Container>
      <DesktopContainer>{SECTIONS?.map(({ render, props }) => render(props))}</DesktopContainer>
    </Container>
  );
};

export default ResearchPageV3;
