import { Gap } from "components/Layout";
import { MonoText, Tag, Title } from "components/v3/shared";
import styled from "styled-components";

const SIDE_MARGIN = 150;

const Container = styled.div`
  display: grid;
  padding-left: ${SIDE_MARGIN}px;
  align-items: center;
  padding: 120px 150px;
  padding-top: 200px;
  background-color: #1f1f1f;
  color: #ffffff;
`;

const HeroBlogs = ({ title, subTitle }) => {
  return (
    <Container>
      <Tag>BLOG</Tag>
      <Gap height="40px" />
      <Title dangerouslySetInnerHTML={{ __html: title }} />
      <Gap height="20px" />
      <MonoText style={{ color: "#ffffff" }} dangerouslySetInnerHTML={{ __html: subTitle }} />
    </Container>
  );
};

export default HeroBlogs;
