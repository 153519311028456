export const BLOG0_TEXT = `
<br>
Leveraging Boltzbit's unique AI Machine Learning solutions and its bespoke workflow model, Liquidnet is now able to receive, process and display newly announced bond deals at a rate which significantly surpasses its prior parsing technology. As a result, bonds are more readily available through Liquidnet's deal announcement dashboard and subsequently into its new issue orderbook.
<br>
<br>
The application of this custom AI model provides Liquidnet's Members and partner syndicate banks with the distinct advantage of faster access to trading and information distribution. Liquidnet estimates the enhancement improves the time from when they receive unstructured deal data to the bonds being ready by 90%.
<br>
<br>
<h2>Mark Russell, Head of Fixed Income Strategy at Liquidnet:</h2>
"We've always been big proponents of interoperability and integrated solutions to bring true efficiency to bond primary markets. Our partnership with Boltzbit is a reflection of that strategy. Rather than rely on traditional Management Information reports, Boltzbit delivered a solution which allows us to interrogate the data via natural language queries, supported by dynamic reports."
<br>
<br>
<h2>Dr Yichuan Zhang, CEO and cofounder at Boltzbit:</h2>
"Bond primary markets remain an area that can benefit from new or improved technology solutions and we are excited to partner with both Liquidnet and TP ICAP who have been at the forefront of this effort. We see GenAI as the next wave of technological innovation for financial services, with significant potential for productivity gains and the creation of new products and services. We believe firms who get ahead of this trend will reap the rewards."
<br>
<br>
Since launching its primary markets offering in 2022, Liquidnet has achieved record trading volumes in its new issue order book and increased European syndicate desk participation to over 35 banks. These milestones underscore the fir's ambition to mordernise primary markets and deliver value to its clients and the industry as a whole. In addition to Boltzbit, Liquidnet previously announced partnerships with NowCM and BondAuction, demonstrating its commitment to collaboration and interoperability to further provide efficiencies and connectivity for investors, banks, and issuers.
<br>
<br>
<h2>About Liquidnet</h2>
Liquidnet is a leading technology-driven, agency execution specialist that intelligently connect the world's investors to the world's investments. Since our founding in 1999, our network has grown to include more that 1,000 institutional investors and spans 57 markets across 6 continents. We built Liquidnet to make global capital markets more efficient and continue to do so by adding additional participants, enabling trusted access to trading and investment opportunities, and delivering actionable intelligence and insight that our customers need. For more information, visit <a target="_blank" href="https://www.liquidnet.com">www.liquidnet.com</a> and follow us on X @Liquidnet
<br>
<br>
<h2>About TP ICAP Group plc</h2>
TP ICAP is a world-leading markets infrastructure and data solutions provider. The Group connects buyers and sellers in wholesale financial, energy and commodities markets. We are the world's largest wholesale market intermediary white a portfolio business that provide broking services, trade execution, data & analytics, and market intelligence. <a target="_blank" href="https://www.tpicap.com">www.tpicap.com</a>
<br>
<br>
<h2>About Boltzbit</h2>
Boltzbit is a leading technology company applying generative AI to the financial services industry. The company makes use of cutting-edge proprietary research that allows it to build efficient and secure models that are customised to applications across finance. Their novel technology is deployed via their software product, simplifying model development and maintenance, and enabling seamless integrations to enterprise applications.`;
