import { Routes, Route, Outlet, useLocation } from "react-router-dom";

import LandingPage from "pages";
import ContactPage from "pages/contact";
import ContactBusinessPage from "pages/contact-business";
import HubContactPage from "pages/hub/contact";
import HubContactBusinessPage from "pages/hub/contact-business";
import EditPage from "pages/edit";
import HubLandingPage from "pages/hub";
import UploadPage from "pages/upload";
import InvestorPage from "pages/investor";
import UnsubscribePage from "pages/unsubscribe";
import ConfirmEmailPage from "pages/confirm-email";
import SurveyPage from "pages/flow/survey";
import LandingPageStart from "pages/flow-start";
import MainPage from "pages/main";
import MainPageNew from "pages/main-new";
import AboutUsPage from "pages/about-us";
import NewsPage from "pages/news";
import ApiPage from "pages/api";
import ResearchPage from "pages/research";
import SolutionsPage from "pages/solutions";
import CareersPage from "pages/careers";
import MainPageV2 from "pages/main-v2";
import AboutUsPageV2 from "pages/about-us-v2";
import ResearchPageV2 from "pages/research-v2";
import PrivacyPolicyPage from "pages/privacy-policy";
import TermsPage from "pages/terms";
import CookiesPage from "pages/cookies";
import { useEffect } from "react";
import CookieBanner from "components/CookieBanner";
import { getCookieByName } from "utils/common";
import ApiPageNew from "pages/api-new";
import BlogsPage from "pages/blogs";
import BlogPage from "pages/blogs/:blogId";
import SignupPage from "pages/signup";
import MainPageV3 from "pages/main-v3";
import BlogsPageV3 from "pages/blogs-v3";
import BlogPageV3 from "pages/blog-v3";
import AboutUsPageV3 from "pages/about-us-v3";
import ResearchPageV3 from "pages/research-v3";
import ProductPageV3 from "pages/product-v3";
import ApiPageV3 from "pages/api-v3";
import FlowPrivacyPage from "pages/flow/privacy-policy";
import FlowTermsPage from "pages/flow/terms";
import SolutionsPageV3 from "pages/solutions-v3";
import CodeGenPage from "pages/code-gen";

const initialiseGA = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    // eslint-disable-next-line no-undef
    dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", "G-W6WGQKC547");
};

const Root = () => {
  const location = useLocation();

  useEffect(() => {
    // const cookiesPolicy = getCookieByName("cookies_policy");
    // if (cookiesPolicy === "ACCEPTED") {
    //   initialiseGA();
    // }
  }, [JSON.stringify(location)]);

  return (
    <>
      <Outlet />
      {/* <CookieBanner
        onClickAcceptAll={() => {
          initialiseGA();
        }}
      /> */}
    </>
  );
};

const PageComponentOnRoute = () => (
  <Routes>
    <Route path="" element={<Root />}>
      <Route path="/" element={<MainPageV3 />} />
      <Route path="/v3" element={<MainPageV3 />} />
      <Route path="/blogs" element={<BlogsPageV3 />} />
      <Route path="/blogs/:blogId" element={<BlogPageV3 />} />
      <Route path="/about-us" element={<AboutUsPageV3 />} />
      <Route path="/research" element={<ResearchPageV3 />} />
      <Route path="/product" element={<ProductPageV3 />} />
      <Route path="/api" element={<ApiPageV3 />} />
      <Route path="/solutions" element={<SolutionsPageV3 />} />
      <Route path="/flow/privacy-policy" element={<FlowPrivacyPage />} />
      <Route path="/flow/terms" element={<FlowTermsPage />} />
      <Route path="/code-gen" element={<CodeGenPage />} />
      <Route path="/old" element={<MainPageNew />} />
      <Route path="/v2" element={<MainPageV2 />} />
      <Route path="/careers" element={<CareersPage />} />
      <Route path="/old" element={<MainPage />} />
      <Route path="/flow" element={<LandingPage />} />
      <Route path="/flow-start" element={<LandingPageStart />} />
      <Route path="/hub" element={<HubLandingPage />} />
      <Route path="/hub/contact" element={<HubContactPage />} />
      <Route path="/flow/contact" element={<ContactPage />} />
      <Route path="/flow/survey" element={<SurveyPage />} />
      <Route path="/edit" element={<EditPage />} />
      <Route path="/upload" element={<UploadPage />} />
      <Route path="/investor" element={<InvestorPage />} />
      <Route path="/hub/contact-business" element={<HubContactBusinessPage />} />
      <Route path="/flow/contact-business" element={<ContactBusinessPage />} />
      <Route path="/unsubscribe" element={<UnsubscribePage />} />
      <Route path="/confirm-email" element={<ConfirmEmailPage />} />
      <Route path="/about-us/old" element={<AboutUsPage />} />
      <Route path="/v2/about-us" element={<AboutUsPageV2 />} />
      <Route path="/news" element={<NewsPage />} />
      <Route path="/v2/api" element={<ApiPage />} />
      <Route path="/api/new" element={<ApiPageNew />} />
      <Route path="/research/old" element={<ResearchPage />} />
      <Route path="/v2/research" element={<ResearchPageV2 />} />
      <Route path="/v2/solutions" element={<SolutionsPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/v2/blogs" element={<BlogsPage />} />
      <Route path="/v2/blogs/:blogId" element={<BlogPage />} />
      <Route path="/signup" element={<SignupPage />} />
      {/* <Route path="/cookies" element={<CookiesPage />} /> */}
    </Route>
  </Routes>
);

export default PageComponentOnRoute;
