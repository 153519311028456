import { GRID_GRAPHIC } from "components/v3/graphics";
import { SubTitle } from "components/v3/shared";
import styled from "styled-components";

const Container = styled.div`
  background-color: #ebebeb;
  display: grid;
  justify-content: center;
  justify-items: center;
  gap: 40px;
  padding: 90px 0;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    display: none;
  }
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 920px;

  & > :last-child {
    border-right: none;
  }
`;

const Column = styled.div`
  display: grid;
  gap: 20px;
  border-right: 1px solid #1f1f1f;
  padding: 0 40px;
  line-height: 1.5;
`;

const ColumnTitle = styled.div`
  font-family: Alexandria;
  font-size: 19px;
  font-weight: 700;
  line-height: 19.95px;
  text-align: left;
`;

const MessagesV3 = ({ title = "", messages = [] }) => {
  return (
    <Container>
      <SubTitle dangerouslySetInnerHTML={{ __html: title }} />
      {GRID_GRAPHIC}
      <Columns>
        {messages?.map((message, i) => (
          <Column key={i}>
            {message.icon}
            <ColumnTitle dangerouslySetInnerHTML={{ __html: message?.title }} />
            <div>{message.text}</div>
          </Column>
        ))}
      </Columns>
    </Container>
  );
};

export default MessagesV3;
