import ApiHero from "components/ApiHero";
import CodeGenHero from "components/CodeGenHero";
import FooterV3 from "components/FooterV3";
import NavFloating from "components/NavFloating";
import { BGS } from "pages/product-v3";

import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { uuidv4 } from "utils/common";

const SECTIONS = [
  {
    render: () => <NavFloating key={uuidv4()} />,
  },
  {
    render: props => <CodeGenHero key={uuidv4()} {...props} />,
    props: {
      title: "Software <span class='blue-highlight'>generation</span>",
      description: "Make your idea a reality. With one prompt.",
      sectionsTitle: "APIs we offer",
      smallSections: [
        {
          title: "Code Generation",
          navTitle: "Code Generation",
          imageSrc: "/images/codegen.gif",
          backgroundImgSrc: BGS[4],
          description: "Our code generation tool will jumpstart your project with a ready-to-deploy codebase.",
        },
      ],
    },
  },
  {
    render: props => <FooterV3 />,
  },
];

const DesktopContainer = styled.div`
  font-family: Alexandria;
  font-size: 16px;
  font-weight: 300;

  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Container = styled.div``;

const CodeGenPage = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("token") === "bzabc") {
      localStorage.setItem("token", searchParams.get("token"));
    }
  }, [searchParams.get("token")]);

  const token = searchParams?.get("token") || localStorage.getItem("token");

  // if (token !== "bzabc") {
  //   return null;
  // }

  return (
    <Container>
      <DesktopContainer>{SECTIONS?.map(({ render, props }) => render(props))}</DesktopContainer>
    </Container>
  );
};

export default CodeGenPage;
