import ContactUsModal from "components/ContactUsModal";
import { Gap } from "components/Layout";
import { LogoWithText, RightShapeIcon } from "components/v3/icons";
import { MonoText } from "components/v3/shared";
import styled from "styled-components";

const Container = styled.div`
  background-color: #303030;
  color: #ffffff;
  * {
    color: #ffffff;
  }
  padding-top: 120px;
  padding: 150px;
  padding-bottom: 40px;

  display: grid;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    padding: 20px;
    padding-top: 60px;
    svg {
      width: 100%;
    }
  }
`;

const TopBannerLink = styled.a`
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0px;

  width: 100%;

  font-family: Martian Mono;
  font-size: 47px;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
  color: white;
  background-color: #1473df;

  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

const LinkText = styled.div`
  padding: 46px 150px;
  padding-right: 0;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    padding: 20px;
    font-size: 18px;
  }
`;

const IconContainer = styled.div`
  background-color: #1f1f1f;
  width: 150px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 50px;
    fill: white;
  }
`;

const StyledGap120 = styled(Gap)`
  height: 120px;
  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    height: 0px;
  }
`;

const StyledGap44 = styled(Gap)`
  height: 44px;
  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    height: 20px;
  }
`;

const Groups = styled.div`
  display: flex;
  gap: 160px;
`;

const Group = styled.div`
  display: grid;
  gap: 20px;
  align-content: start;
`;

const GroupAnchor = styled.a`
  text-decoration: none;
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #666666;
`;

const BottomLinksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  font-family: Martian Mono;
  font-size: 12px;

  @media (max-width: ${props => props?.theme?.breakpoint?.lg}) {
    grid-template-columns: 1fr;
    gap: 20px;
    font-size: 10px;
  }
`;

const BottomLinks = styled.div`
  display: flex;
  gap: 40px;
`;

const BANNER_TEXT = "Talk to us";
const LINK_GROUPS = [
  // {
  //   title: "Contact",
  //   links: [
  //     { title: "Email", href: "/" },
  //     { title: "Chat", href: "/" },
  //   ],
  // },
  {
    title: "Product",
    links: [
      { title: "Terms", href: "/flow/terms" },
      { title: "Data Policy", href: "/flow/privacy-policy" },
    ],
  },
];

const FooterV3 = () => {
  return (
    <>
      <ContactUsModal
        triggerComponent={
          <TopBannerLink>
            <LinkText>{BANNER_TEXT}</LinkText>
            <IconContainer>
              <RightShapeIcon style={{ height: 50 }} />
            </IconContainer>
          </TopBannerLink>
        }
      />

      <Container>
        <Groups>
          {LINK_GROUPS.map((group, index) => (
            <Group key={index}>
              <MonoText>{group.title}</MonoText>
              {group.links.map((link, index) => (
                <GroupAnchor key={index} href={link.href}>
                  {link.title}
                </GroupAnchor>
              ))}
            </Group>
          ))}
        </Groups>
        <StyledGap120 />
        <LogoWithText style={{ justifySelf: "center" }} />
        <Gap height="44px" />
        <HorizontalLine />
        <StyledGap44 />
        <BottomLinksContainer>
          <BottomLinks>
            <GroupAnchor href="https://www.linkedin.com/company/boltzbit">LinkedIn</GroupAnchor>
          </BottomLinks>
          <BottomLinks>
            <GroupAnchor href="/privacy-policy">Privacy</GroupAnchor>
            <GroupAnchor href="/terms">Terms of use</GroupAnchor>
            <GroupAnchor>©Boltzbit 2024</GroupAnchor>
          </BottomLinks>
        </BottomLinksContainer>
      </Container>
    </>
  );
};

export default FooterV3;
