import ContactUsModal from "components/ContactUsModal";
import { Gap } from "components/Layout";
import { ButtonLink, MonoText, Tag, Title } from "components/v3/shared";
import styled from "styled-components";

const Container = styled.div`
  display: grid;

  align-items: center;
  align-content: center;

  justify-content: center;
  justify-items: center;

  text-align: center;
  background-color: ${props => props.bgColor};
  min-height: ${props => props.minHeight};
`;

const TitleWithTag = ({
  bgColor = "#ffffff",
  bottomGapHeight = "200px",
  minHeight = "100vh",
  title,
  buttonHref,
  subTitle,
  buttonText,
  tagText = "",
}) => {
  return (
    <Container minHeight={minHeight} bgColor={bgColor}>
      <Tag color="#1F1F1F">{tagText}</Tag>
      <Gap height="40px" />
      <Title style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: title }} />
      <Gap height="20px" />
      <MonoText dangerouslySetInnerHTML={{ __html: subTitle }} />
      {buttonText && <Gap height="60px" />}
      {buttonText && <ContactUsModal triggerComponent={<ButtonLink>{buttonText}</ButtonLink>} />}
      <Gap height={bottomGapHeight} />
    </Container>
  );
};

export default TitleWithTag;
